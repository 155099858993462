import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, LinearProgress } from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import GameSectionBody from './SectionBody';

interface GameSectionProps {
  starCount: number;
  name: string;
  description: string;
  color: string;
  id: number;
  steps: Array<Step>;
  totalSteps: number;
  completedSteps: number;
}

interface Step {
  id: string;
  name: string;
  completionStatus: {
    completed: boolean;
    completed_at: string;
  };
}

const GameSectionHeader: React.FC<GameSectionProps> = ({
  starCount,
  name,
  description,
  color,
  id,
  steps,
  totalSteps,
  completedSteps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [minProgress, setMinProgress] = useState<number>(0);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    //pause for a moment to allow the progress bar to animate
    setTimeout(() => {
      setMinProgress(Math.max((completedSteps / totalSteps) * 100, 1));
    }, 100);
  });

  return (
    <div>
      <Box
        key={id}
        style={{
          backgroundColor: color,
          borderRadius: '10px',
          padding: '20px',
          width: '98%',
          color: 'white',
          textAlign: 'left',
          position: 'relative' // Add relative positioning
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '30px',
            right: '20px',
            transform: 'translateY(-50%)'
          }}
        >
          <IconButton onClick={handleToggle}>
            {isOpen ? (
              <ArrowCircleUpIcon
                style={{ fontSize: '2.5rem', strokeWidth: '2', color: 'white' }}
              />
            ) : (
              <ArrowCircleDownIcon
                style={{ fontSize: '2.5rem', strokeWidth: '2', color: 'white' }}
              />
            )}
          </IconButton>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}
        >
          <Typography style={{ fontSize: '1.1em' }}>
            <strong>{name}</strong>
          </Typography>
        </div>
        <Typography
          variant='body1'
          style={{ marginTop: '10px', textAlign: 'left' }}
        >
          {description}
        </Typography>
        <LinearProgress
          className='progress-bar'
          variant='determinate'
          color='inherit'
          value={minProgress}
          style={{ marginTop: '10px', height: '8px', borderRadius: '5px' }}
        />
      </Box>
      {isOpen && <GameSectionBody steps={steps} starCount={starCount} />}
    </div>
  );
};

export default GameSectionHeader;
