import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Typography,
  Paper,
  Box,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { getBeginStep } from '../../../../clients/rotr-client';
import { UUID } from 'crypto';
import NavCompact from '../../../header/NavCompact';
import { styled } from '@mui/material/styles';
import { Container, Grid, Card, CardContent, Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import QuizIcon from '@mui/icons-material/Quiz';
import MenuBookIcon from '@mui/icons-material/MenuBook'; // New import for instructions icon

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.custom.page,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderRadius: 0
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
  backgroundColor: '#1d2125',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius
}));

const StepHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  width: '100%'
}));

const StepIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.custom.page,
  borderRadius: '50%',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StepInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));

const BeginStep: React.FC = () => {
  const { stepId } = useParams<{ stepId?: UUID }>();
  const [stepTitle, setStepTitle] = useState('');
  const [stepDescription, setStepDescription] = useState('');
  const [instructions, setInstructions] = useState('');
  const [points, setPoints] = useState('');
  const [dateTime, setDateTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stepCompleted, setStepCompleted] = useState(false);
  // State to hold the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Dynamic styles based on window width
  const dynamicStyles = {
    iconSize: windowWidth < 768 ? 50 : 100, // Smaller icon size for smaller screens
    boxPadding: windowWidth < 768 ? '10px' : '20px', // Smaller padding for smaller screens
    boxMarginLeft: windowWidth < 768 ? '50px' : '100px', // Adjust left margin for smaller screens
    boxMarginRight: windowWidth < 768 ? '10px' : '20px' // Adjust right margin for smaller screens
  };
  const [error, setError] = useState<string | null>(null);

  const fetchBeginStep = async (token: string | null = null, stepId?: UUID) => {
    if (stepId) {
      try {
        const response = await getBeginStep(stepId, token);
        // Assuming response contains the step details
        setStepTitle(response.step.name);
        setStepDescription(response.step.description);
        setInstructions(response.step.instructions);
        setPoints(response.highestScore);
        if (response.completionStatus.completed) {
          setStepCompleted(true);
          const localDateTime = new Date(
            response.completionStatus.completed_at
          ).toLocaleString();
          setDateTime(localDateTime);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch step details:', error);
        setError('Failed to fetch step details. Please try again.');
      }
    }
  };

  useEffect(() => {
    if (stepId) {
      fetchBeginStep(null, stepId);
    }
  }, [stepId]);

  const linkPath = `/games/shake-down/quiz/${stepId}`;
  if (isLoading) {
    return (
      <StyledPaper>
        <CircularProgress />
      </StyledPaper>
    );
  }

  return (
    <StyledPaper>
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <Box sx={{ mb: 2 }}>
          <NavCompact />
        </Box>

        {error && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={3} sx={{ mb: 2, backgroundColor: '#1d2125' }}>
              <CardContent sx={{ p: 2 }}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  mb={1}
                >
                  <QuizIcon
                    sx={{ mr: 1, color: 'primary.main', fontSize: 32 }}
                  />
                  <Typography variant='h5' align='center'>
                    {stepTitle}
                  </Typography>
                </Box>
                <Typography variant='body2' color='text.secondary'>
                  {stepDescription}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card elevation={3} sx={{ mb: 2, backgroundColor: '#1d2125' }}>
              <CardContent sx={{ p: 2 }}>
                <Box display='flex' alignItems='center' mb={1}>
                  <MenuBookIcon
                    sx={{ mr: 1, color: 'warning.main', fontSize: 24 }}
                  />
                  <Typography variant='h6'>Instructions</Typography>
                </Box>
                <Typography variant='body2' align='left'>
                  {instructions}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {stepCompleted ? (
            <Grid item xs={12}>
              <Card
                variant='outlined'
                sx={{
                  bgcolor: 'success.dark',
                  color: 'success.contrastText',
                  p: 2,
                  mb: 2,
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 23,
                    right: -33,
                    transform: 'rotate(45deg)',
                    bgcolor: 'success.light',
                    color: 'success.dark',
                    px: 4,
                    py: 0.5,
                    width: 150,
                    textAlign: 'center'
                  }}
                >
                  <Typography variant='body2' fontWeight='bold'>
                    COMPLETED
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' mb={1}>
                  <CheckCircleOutlineIcon sx={{ mr: 1, fontSize: 30 }} />
                  <Typography variant='h6'>Quiz Already Completed!</Typography>
                </Box>
                <Box display='flex' alignItems='center' mb={1}>
                  <AccessTimeIcon sx={{ mr: 1 }} />
                  <Typography variant='body1'>
                    Completed on: {dateTime}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center'>
                  <EmojiEventsIcon sx={{ mr: 1 }} />
                  <Typography variant='body1'>
                    Highest Score: {points} points
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Box
              display='flex'
              justifyContent='center'
              flexDirection='column'
              alignItems='center'
              sx={{ mt: 1 }}
            >
              <Button
                component={Link}
                to={`/games/shake-down/quiz/${stepId}`}
                variant='contained'
                color='primary'
                size='large'
                startIcon={<QuizIcon />}
                sx={{ mb: 1, py: 1, px: 3, fontSize: '1rem' }}
              >
                {stepCompleted ? 'RETAKE QUIZ' : 'START QUIZ'}
              </Button>
              <Typography variant='body2' color='text.secondary'>
                Click the button above to {stepCompleted ? 'retake' : 'begin'}{' '}
                the quiz for "{stepTitle}".
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledPaper>
  );
};

export default BeginStep;
