import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ListUserTasks } from '../../clients/rotr-client';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Checkbox,
  Container,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Snackbar,
  Tooltip,
  Typography
} from '@mui/material';
import RadarIcon from '@mui/icons-material/Radar';
import ListIcon from '@mui/icons-material/List';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Feedback from '../scenario feedback/Feedback2';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import QuizIcon from '@mui/icons-material/Quiz';
import { Tool } from 'paper/dist/paper-core';
import * as amplitude from '@amplitude/analytics-browser';

interface ClassTaskData {
  taskID: string;
  taskType: string;
  taskName: string;
  taskURL: string;
  taskPracticeLink: string;
  completedAt: string | undefined;
  completionReference?: string;
}

const StyledContainer = styled(Container)({
  marginTop: '5rem',
  textAlign: 'center',
  '@media (min-width: 600px)': {
    maxWidth: '600px' // Set max width to 60px on large screens
  }
});

const LargeTypography = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '1rem'
});

interface TasksProps {
  userID?: string;
  classID?: string;
  name?: string;
}

const Tasks = ({ name, userID, classID }: TasksProps) => {
  const [userTasks, setUserTasks] = useState<ClassTaskData[]>([]);
  const [feedbackId, setFeedbackId] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleClose = () => setOpen(false);
  const handleCloseError = () => setOpenError(false);

  const navigate = useNavigate();

  const handleTaskClick = (task: any) => {
    switch (task.taskType) {
      case 'recite':
        amplitude.logEvent('Started Recite Task');
        break;
      case 'scenario':
        amplitude.logEvent('Generated Task Scenario');
        break;
      case 'quiz':
        amplitude.logEvent('Started Quiz Task');
        break;
      default:
    }

    navigate(task.taskPracticeLink);
  };

  const handleRetryTaskClick = (userID: string | undefined, task: any) => {
    if (userID) {
      return;
    } else {
      handleTaskClick(task);
    }
  };

  const getTaskIcon = (taskType: string) => {
    switch (taskType) {
      case 'recite':
        return <RecordVoiceOverIcon fontSize='large' />;
      case 'scenario':
        return <RadarIcon fontSize='large' />;
      case 'quiz':
        return <QuizIcon fontSize='large' />;
      case 'replay':
        return <ReplayCircleFilledIcon fontSize='large' />;
      case 'viewFeedback':
        return <AssessmentIcon fontSize='large' />;
      case 'attempt':
        return <StartOutlinedIcon fontSize='large' />;
      default:
        return <RadarIcon fontSize='large' />;
    }
  };

  const getUserTasks = async () => {
    try {
      const tasks = await ListUserTasks(userID, classID);
      const classTasks: ClassTaskData[] = tasks.map(
        (t: {
          id: string;
          task_type: string;
          task_title: string;
          task_url: string;
          template_id: string;
          completed_at: Date;
          reference_id: string;
        }) => {
          return {
            taskID: t.id,
            taskType: t.task_type,
            taskName: t.task_title,
            taskURL: t.task_url,
            taskPracticeLink:
              (t.task_type === 'recite'
                ? '/practice/recite/'
                : t.task_type === 'quiz'
                ? '/games/shake-down/steps/'
                : '/practice/') + t.task_url,
            completedAt: t.completed_at
              ? new Date(t.completed_at).toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour12: false
                })
              : undefined,
            completionReference: t.reference_id
          };
        }
      );
      setUserTasks(classTasks);
    } catch (error: any) {
      console.error(error);
      setErrMsg(error.message);
      setOpenError(true);
    }
  };

  useEffect(() => {
    getUserTasks();
  }, [userID, classID]);
  return (
    <div className={'main-content'}>
      <StyledContainer>
        <Typography
          variant='h4'
          gutterBottom
          align='center'
          sx={{ mt: 2, mb: 4 }}
        >
          Your Tasks
        </Typography>
        {userTasks.length > 0 ? (
          <>
            {userTasks.map((task: ClassTaskData) => {
              return (
                <ListItem
                  sx={{ border: 'solid #2c3238 1px' }}
                  className='taskItem'
                  key={task.taskID}
                  secondaryAction={
                    <>
                      {task.completedAt ? (
                        <>
                          {task.taskType === 'scenario' ? (
                            <>
                              <Tooltip title='View Feedback' arrow>
                                <IconButton
                                  edge='end'
                                  aria-label='try-again'
                                  style={{ color: 'white' }}
                                  onClick={() => {
                                    setFeedbackId(task.completionReference!);
                                    setOpen(true);
                                  }}
                                >
                                  {getTaskIcon('viewFeedback')}
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          )}

                          {userID ? null : (
                            <Tooltip title='Try again' arrow>
                              <IconButton
                                edge='end'
                                aria-label='try-again'
                                style={{ color: 'white' }}
                                onClick={() => handleTaskClick(task)}
                              >
                                {getTaskIcon('replay')}
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <>
                          {userID ? null : (
                            <Tooltip title={`Attempt ${task.taskName}`} arrow>
                              <IconButton
                                edge='end'
                                aria-label='start'
                                style={{ color: 'white' }}
                                onClick={() => handleTaskClick(task)}
                              >
                                {getTaskIcon('attempt')}
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  }
                >
                  {userID ? (
                    <>
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={task.completedAt ? true : false}
                          tabIndex={-1}
                          disabled
                          disableRipple
                          style={{ color: '#12A67C' }}
                        />
                      </ListItemIcon>
                      {/* <ListItemButton> */}
                      <ListItemIcon style={{ color: 'white' }}>
                        {getTaskIcon(task.taskType)}
                      </ListItemIcon>
                      <ListItemText
                        primary={task.taskName}
                        secondary={
                          task.completedAt
                            ? 'completed at: ' + task.completedAt
                            : undefined
                        }
                      />
                      {/* </ListItemButton> */}
                    </>
                  ) : (
                    <Tooltip
                      title={`${task.completedAt ? 'Retry' : 'Attempt'} ${
                        task.taskName
                      }`}
                      arrow
                    >
                      <ListItemButton
                        onClick={() => {
                          handleRetryTaskClick(userID, task);
                        }}
                      >
                        <ListItemIcon style={{ color: 'white' }}>
                          {getTaskIcon(task.taskType)}
                        </ListItemIcon>
                        <ListItemText
                          style={{ marginRight: '24px' }}
                          primary={task.taskName}
                          secondary={
                            task.completedAt
                              ? 'completed at: ' + task.completedAt
                              : undefined
                          }
                          secondaryTypographyProps={{
                            style: { color: '#17ABD4' }
                          }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  )}
                  <Divider style={{ backgroundColor: 'white' }} />
                </ListItem>
              );
            })}
          </>
        ) : (
          'No tasks to complete.'
        )}
      </StyledContainer>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Paper
          style={{
            width: '95%',
            maxHeight: '90%', // 90% height
            overflowY: 'auto',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '5%',
              left: '1%'
            }}
            onClick={handleClose}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <Feedback inputId={feedbackId} />
        </Paper>
      </Modal>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Tasks;
