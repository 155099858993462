import { Rule } from './ruleTypes';
export const rules: Rule[] = [
  {
    id: '1',
    title: 'Rule 1 - Application',
    description: '',
    part: 'Part A - General',
    subRules: [
      {
        id: '1-a',
        title: '(a)',
        description:
          'These Rules shall apply to all vessels upon the high seas and in all waters connected therewith navigable by seagoing vessels.'
      },
      {
        id: '1-b',
        title: '(b)',
        description:
          'Nothing in these Rules shall interfere with the operation of special rules made by an appropriate authority for roadsteads, harbours, rivers, lakes or inland waterways connected with the high seas and navigable by seagoing vessels. Such special rules shall conform as closely as possible to these Rules.'
      },
      {
        id: '1-c',
        title: '(c)',
        description:
          'Nothing in these Rules shall interfere with the operation of any special rules made by the Government of any State with respect to additional station or signal lights, shapes or whistle signals for ships of war and vessels proceeding under convoy, or with respect to additional station or signal lights or shapes for fishing vessels engaged in fishing as a fleet. These additional station or signal lights, shapes or whistle signals shall, so far as possible, be such that they cannot be mistaken for any light, shape or signal authorised elsewhere under these Rules.'
      },
      {
        id: '1-d',
        title: '(d)',
        description:
          'Traffic separation schemes may be adopted by the Organisation for the purpose of these Rules.'
      },
      {
        id: '1-e',
        title: '(e)',
        description:
          'Whenever the Government concerned shall have determined that a vessel of any special construction or purpose cannot comply with the provisions of any of these Rules with respect to the number, position, range or arc of visibility of lights or shapes, as well as to the disposition and characteristics of sound-signalling appliances, such vessel shall comply with such other provisions in regard to the number, position, range or arc of visibility of lights or shapes, as well as to the disposition and characteristics of sound-signalling appliances, as her Government shall have determined to be the closest possible compliance with these Rules in respect of that vessel.'
      }
    ]
  },
  {
    id: '2',
    title: 'Rule 2 - Responsibility',
    description: '',
    part: 'Part A - General',
    subRules: [
      {
        id: '2-a',
        title: '(a)',
        description:
          'Nothing in these Rules shall exonerate any vessel, or the owner, master or crew thereof, from the consequences of any neglect to comply with these Rules or of the neglect of any precaution which may be required by the ordinary practice of seamen, or by the special circumstances of the case.'
      },
      {
        id: '2-b',
        title: '(b)',
        description:
          'In construing and complying with these Rules due regard shall be had to all dangers of navigation and collision and to any special circumstances, including the limitations of the vessels involved, which may make a departure from these Rules necessary to avoid immediate danger.'
      }
    ]
  },
  {
    id: '3',
    title: 'Rule 3 - General Definitions',
    description:
      'For the purpose of these Rules, except where the context otherwise requires:',
    part: 'Part A - General',
    subRules: [
      {
        id: '3-a',
        title: '(a)',
        description:
          'The word “vessel” includes every description of water craft, including non-displacement craft, WIG craft and seaplanes, used or capable of being used as a means of transportation on water.'
      },
      {
        id: '3-b',
        title: '(b)',
        description:
          'The term “power-driven vessel” means any vessel propelled by machinery.'
      },
      {
        id: '3-c',
        title: '(c)',
        description:
          'The term “sailing vessel” means any vessel under sail provided that propelling machinery, if fitted, is not being used.'
      },
      {
        id: '3-d',
        title: '(d)',
        description:
          'The term “vessel engaged in fishing” means any vessel fishing with nets, lines, trawls or other fishing apparatus which restrict manoeuvrability, but does not include a vessel fishing with trolling lines or other fishing apparatus which do not restrict manoeuvrability.'
      },
      {
        id: '3-e',
        title: '(e)',
        description:
          'The word “seaplane” includes any aircraft designed to manoeuvre on the water.'
      },
      {
        id: '3-f',
        title: '(f)',
        description:
          'The term “vessel not under command” means a vessel which through some exceptional circumstance is unable to manoeuvre as required by these Rules and is therefore unable to keep out of the way of another vessel.'
      },
      {
        id: '3-g',
        title: '(g)',
        description: '',
        subSubRules: [
          {
            id: '3-g-intro',
            title: 'Intro',
            description:
              'The term “vessel restricted in her ability to manoeuvre” means a vessel which from the nature of her work is restricted in her ability to manoeuvre as required by these Rules and is therefore unable to keep out of the way of another vessel. The term “vessels restricted in their ability to manoeuvre” shall include but not be limited to:'
          },
          {
            id: '3-g-i',
            title: '(i)',
            description:
              'a vessel engaged in laying, servicing or picking up a navigation mark, submarine cable or pipeline;'
          },
          {
            id: '3-g-ii',
            title: '(ii)',
            description:
              'a vessel engaged in dredging, surveying or underwater operations;'
          },
          {
            id: '3-g-iii',
            title: '(iii)',
            description:
              'a vessel engaged in replenishment or transferring persons, provisions or cargo while underway;'
          },
          {
            id: '3-g-iv',
            title: '(iv)',
            description:
              'a vessel engaged in the launching or recovery of aircraft;'
          },
          {
            id: '3-g-v',
            title: '(v)',
            description: 'a vessel engaged in mine clearance operations;'
          },
          {
            id: '3-g-vi',
            title: '(vi)',
            description:
              'a vessel engaged in a towing operation such as severely restricts the towing vessel and her tow in their ability to deviate from their course.'
          }
        ]
      },
      {
        id: '3-h',
        title: '(h)',
        description:
          'The term “vessel constrained by her draught” means a power-driven vessel which, because of her draught in relation to the available depth and width of navigable water, is severely restricted in her ability to deviate from the course she is following.'
      },
      {
        id: '3-i',
        title: '(i)',
        description:
          'The word “underway” means that a vessel is not at anchor, or made fast to the shore, or aground.'
      },
      {
        id: '3-j',
        title: '(j)',
        description:
          'The words “length” and “breadth” of a vessel mean her length overall and greatest breadth.'
      },
      {
        id: '3-k',
        title: '(k)',
        description:
          'Vessels shall be deemed to be in sight of one another only when one can be observed visually from the other.'
      },
      {
        id: '3-l',
        title: '(l)',
        description:
          'The term “restricted visibility” means any condition in which visibility is restricted by fog, mist, falling snow, heavy rainstorms, sandstorms or any other similar causes.'
      },
      {
        id: '3-m',
        title: '(m)',
        description:
          'The term “Wing-in-Ground (WIG) craft” means a multimodal craft which, in its main operational mode, flies in close proximity to the surface by utilizing surface-effect action.'
      }
    ]
  },
  {
    id: '4',
    title: 'Rule 4 - Application',
    description: 'Rules in this Section apply in any condition of visibility',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility'
  },
  {
    id: '5',
    title: 'Rule 5 - Look Out',
    description:
      'Every vessel shall at all times maintain a proper look-out by sight and hearing as well as by all available means appropriate in the prevailing circumstances and conditions so as to make a full appraisal of the situation and of the risk of collision.',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility'
  },
  {
    id: '6',
    title: 'Rule 6 - Safe Speed',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility',
    subRules: [
      {
        id: '6-intro',
        title: 'Intro',
        description:
          'Every vessel shall at all times proceed at a safe speed so that she can take proper and effective action to avoid collision and be stopped within a distance appropriate to the prevailing circumstances and conditions. In determining a safe speed the following factors shall be among those taken into account:'
      },
      {
        id: '6-a',
        title: '(a)',
        description: 'By all vessels:',
        subSubRules: [
          {
            id: '6-a-i',
            title: '(i)',
            description: 'the state of visibility;'
          },
          {
            id: '6-a-ii',
            title: '(ii)',
            description:
              'the traffic density including concentrations of fishing vessels or any other vessels;'
          },
          {
            id: '6-a-iii',
            title: '(iii)',
            description:
              'the manoeuvrability of the vessel with special reference to stopping distance and turning ability in the prevailing conditions;'
          },
          {
            id: '6-a-iv',
            title: '(iv)',
            description:
              'at night the presence of background light such as from shore lights or from back scatter of her own lights;'
          },
          {
            id: '6-a-v',
            title: '(v)',
            description:
              'the state of wind, sea and current, and the proximity of navigational hazards;'
          },
          {
            id: '6-a-vi',
            title: '(vi)',
            description:
              'the draught in relation to the available depth of water.'
          }
        ]
      },
      {
        id: '6-b',
        title: '(b)',
        description: 'Additionally, by vessels with operational radar:',
        subSubRules: [
          {
            id: '6-b-i',
            title: '(i)',
            description:
              'the characteristics, efficiency and limitations of the radar equipment;'
          },
          {
            id: '6-b-ii',
            title: '(ii)',
            description:
              'any constraints imposed by the radar range scale in use;'
          },
          {
            id: '6-b-iii',
            title: '(iii)',
            description:
              'the effect on radar detection of the sea state, weather and other sources of interference;'
          },
          {
            id: '6-b-iv',
            title: '(iv)',
            description:
              'the possibility that small vessels, ice and other floating objects may not be detected by radar at an adequate range;'
          },
          {
            id: '6-b-v',
            title: '(v)',
            description:
              'the number, location and movement of vessels detected by radar;'
          },
          {
            id: '6-b-vi',
            title: '(vi)',
            description:
              'the more exact assessment of the visibility that may be possible when radar is used to determine the range of vessels or other objects in the vicinity.'
          }
        ]
      }
    ]
  },
  {
    id: '7',
    title: 'Rule 7 - Risk of Collision',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility',
    subRules: [
      {
        id: '7-a',
        title: '(a)',
        description:
          'Every vessel shall use all available means appropriate to the prevailing circumstances and conditions to determine if risk of collision exists. If there is any doubt such risk shall be deemed to exist.'
      },
      {
        id: '7-b',
        title: '(b)',
        description:
          'Proper use shall be made of radar equipment if fitted and operational, including long-range scanning to obtain early warning of risk of collision and radar plotting or equivalent systematic observation of detected objects.'
      },
      {
        id: '7-c',
        title: '(c)',
        description:
          'Assumptions shall not be made on the basis of scanty information, especially scanty radar information.'
      },
      {
        id: '7-d',
        title: '(d)',
        description:
          'In determining if risk of collision exists the following considerations shall be among those taken into account:',
        subSubRules: [
          {
            id: '7-d-i',
            title: '(i)',
            description:
              'such risk shall be deemed to exist if the compass bearing of an approaching vessel does not appreciably change;'
          },
          {
            id: '7-d-ii',
            title: '(ii)',
            description:
              'such risk may sometimes exist even when an appreciable bearing change is evident, particularly when approaching a very large vessel or a tow or when approaching a vessel at close range.'
          }
        ]
      }
    ]
  },
  {
    id: '8',
    title: 'Rule 8 - Action to avoid collision',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility',
    subRules: [
      {
        id: '8-a',
        title: '(a)',
        description:
          'Any action taken to avoid collision shall be taken in accordance with the Rules of this Part and shall, if the circumstances of the case admit, be positive, made in ample time and with due regard to the observance of good seamanship.'
      },
      {
        id: '8-b',
        title: '(b)',
        description:
          'Any alteration of course and/or speed to avoid collision shall, if the circumstances of the case admit, be large enough to be readily apparent to another vessel observing visually or by radar; a succession of small alterations of course and/or speed should be avoided.'
      },
      {
        id: '8-c',
        title: '(c)',
        description:
          'If there is sufficient sea-room, alteration of course alone may be the most effective action to avoid a close-quarters situation provided that it is made in good time, is substantial and does not result in another close-quarters situation.'
      },
      {
        id: '8-d',
        title: '(d)',
        description:
          'Action taken to avoid collision with another vessel shall be such as to result in passing at a safe distance. The effectiveness of the action shall be carefully checked until the other vessel is finally past and clear.'
      },
      {
        id: '8-e',
        title: '(e)',
        description:
          'If necessary to avoid collision or allow more time to assess the situation, a vessel shall slacken her speed or take all way off by stopping or reversing her means of propulsion.'
      },
      {
        id: '8-f',
        title: '(f)',
        description: '',
        subSubRules: [
          {
            id: '8-f-i',
            title: '(i)',
            description:
              'A vessel which, by any of these Rules, is required not to impede the passage or safe passage of another vessel shall, when required by the circumstances of the case, take early action to allow sufficient sea-room for the safe passage of the other vessel.'
          },
          {
            id: '8-f-ii',
            title: '(ii)',
            description:
              'A vessel required not to impede the passage or safe passage of another vessel is not relieved of this obligation if approaching the other vessel so as to involve risk of collision and shall, when taking action, have full regard to the action which may be required by the Rules of this Part.'
          },
          {
            id: '8-f-iii',
            title: '(iii)',
            description:
              'A vessel the passage of which is not to be impeded remains fully obliged to comply with the Rules of this Part when the two vessels are approaching one another so as to involve risk of collision.'
          }
        ]
      }
    ]
  },
  {
    id: '9',
    title: 'Rule 9 - Narrow channels',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility',
    subRules: [
      {
        id: '9-a',
        title: '(a)',
        description:
          'A vessel proceeding along the course of a narrow channel or fairway shall keep as near to the outer limit of the channel or fairway which lies on her starboard side as is safe and practicable.'
      },
      {
        id: '9-b',
        title: '(b)',
        description:
          'A vessel of less than 20 meters in length or a sailing vessel shall not impede the passage of a vessel which can safely navigate only within a narrow channel or fairway.'
      },
      {
        id: '9-c',
        title: '(c)',
        description:
          'A vessel engaged in fishing shall not impede the passage of any other vessel navigating within a narrow channel or fairway.'
      },
      {
        id: '9-d',
        title: '(d)',
        description:
          'A vessel shall not cross a narrow channel or fairway if such crossing impedes the passage of a vessel which can safely navigate only within such channel or fairway. The latter vessel may use the sound signal prescribed in Rule 34(d) if in doubt as to the intention of the crossing vessel.'
      },
      {
        id: '9-e',
        title: '(e)',
        description: '',
        subSubRules: [
          {
            id: '9-e-i',
            title: '(i)',
            description:
              'In a narrow channel or fairway when overtaking can take place only if the vessel to be overtaken has to take action to permit safe passing, the vessel intending to overtake shall indicate her intention by sounding the appropriate signal prescribed in Rule 34(c)(i). The vessel to be overtaken shall, if in agreement, sound the appropriate signal prescribed in Rule 34(c)(ii) and take steps to permit safe passing. If in doubt she may sound the signal prescribed in Rule 34(d).'
          },
          {
            id: '9-e-ii',
            title: '(ii)',
            description:
              'This Rule does not relieve the overtaking vessel of her obligation under Rule 13.'
          }
        ]
      },
      {
        id: '9-f',
        title: '(f)',
        description:
          'A vessel nearing a bend or an area of a narrow channel or fairway where other vessels may be obscured by an intervening obstruction shall navigate with particular alertness and caution and shall sound the appropriate signal prescribed in Rule 34(e).'
      },
      {
        id: '9-g',
        title: '(g)',
        description:
          'Any vessel shall, if the circumstances of the case admit, avoid anchoring in a narrow channel.'
      }
    ]
  },
  {
    id: '10',
    title: 'Rule 10 - Traffic separation schemes',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section I Conduct of vessels in any condition of visibility',
    subRules: [
      {
        id: '10-a',
        title: '(a)',
        description:
          'This Rule applies to traffic separation schemes adopted by the Organization and does not relieve any vessel of her obligation under any other Rule.'
      },
      {
        id: '10-b',
        title: '(b)',
        description: 'A vessel using a traffic separation scheme shall:',
        subSubRules: [
          {
            id: '10-b-i',
            title: '(i)',
            description:
              'proceed in the appropriate traffic lane in the general direction of traffic flow for that lane;'
          },
          {
            id: '10-b-ii',
            title: '(ii)',
            description:
              'so far as practicable keep clear of a traffic separation line or separation zone;'
          },
          {
            id: '10-b-iii',
            title: '(iii)',
            description:
              'normally join or leave a traffic lane at the termination of the lane, but when joining or leaving from either side shall do so at as small an angle to the general direction of traffic flow as practicable.'
          }
        ]
      },
      {
        id: '10-c',
        title: '(c)',
        description:
          'A vessel shall, so far as practicable, avoid crossing traffic lanes but if obliged to do so shall cross on a heading as nearly as practicable at right angles to the general direction of traffic flow.'
      },
      {
        id: '10-d',
        title: '(d)',
        description: '',
        subSubRules: [
          {
            id: '10-d-i',
            title: '(i)',
            description:
              'A vessel shall not use an inshore traffic zone when she can safely use the appropriate traffic lane within the adjacent traffic separation scheme. However, vessels of less than 20 meters in length, sailing vessels and vessels engaged in fishing may use the inshore traffic zone.'
          },
          {
            id: '10-d-ii',
            title: '(ii)',
            description:
              'Notwithstanding sub-paragraph (d)(i), a vessel may use an inshore traffic zone when en route to or from a port, offshore installation or structure, pilot station or any other place situated within the inshore traffic zone, or to avoid immediate danger.'
          }
        ]
      },
      {
        id: '10-e',
        title: '(e)',
        description: '',
        subSubRules: [
          {
            id: '10-e-intro',
            title: 'Intro',
            description:
              'A vessel other than a crossing vessel or a vessel joining or leaving a lane shall not normally enter a separation zone or cross a separation line except:'
          },
          {
            id: '10-e-i',
            title: '(i)',
            description: 'in cases of emergency to avoid immediate danger;'
          },
          {
            id: '10-e-ii',
            title: '(ii)',
            description: 'to engage in fishing within a separation zone.'
          }
        ]
      },
      {
        id: '10-f',
        title: '(f)',
        description:
          'A vessel navigating in areas near the terminations of traffic separation schemes shall do so with particular caution.'
      },
      {
        id: '10-g',
        title: '(g)',
        description:
          'A vessel shall so far as practicable avoid anchoring in a traffic separation scheme or in areas near its terminations.'
      },
      {
        id: '10-h',
        title: '(h)',
        description:
          'A vessel not using a traffic separation scheme shall avoid it by as wide a margin as is practicable.'
      },
      {
        id: '10-i',
        title: '(i)',
        description:
          'A vessel engaged in fishing shall not impede the passage of any vessel following a traffic lane.'
      },
      {
        id: '10-j',
        title: '(j)',
        description:
          'A vessel of less than 20 meters in length or a sailing vessel shall not impede the safe passage of a power-driven vessel following a traffic lane.'
      },
      {
        id: '10-k',
        title: '(k)',
        description:
          'A vessel restricted in her ability to manoeuvre when engaged in an operation for the maintenance of safety of navigation in a traffic separation scheme is exempted from complying with this Rule to the extent necessary to carry out the operation.'
      },
      {
        id: '10-l',
        title: '(l)',
        description:
          'A vessel restricted in her ability to manoeuvre when engaged in an operation for the laying, servicing or picking up of a submarine cable, within a traffic separation scheme, is exempted from complying with this Rule to the extent necessary to carry out the operation.'
      }
    ]
  },
  {
    id: '11',
    title: 'Rule 11 - Application',
    description:
      'Rules in this Section apply to vessels in sight of one another',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another'
  },
  {
    id: '12',
    title: 'Rule 12 - Sailing Vessels',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another',
    subRules: [
      {
        id: '12-a',
        title: '(a)',
        description:
          'When two sailing vessels are approaching one another, so as to involve risk of collision, one of them shall keep out of the way of the other as follows:',
        subSubRules: [
          {
            id: '12-a-i',
            title: '(i)',
            description:
              'when each has the wind on a different side, the vessel which has the wind on the port side shall keep out of the way of the other;'
          },
          {
            id: '12-a-ii',
            title: '(ii)',
            description:
              'when both have the wind on the same side, the vessel which is to windward shall keep out of the way of the vessel which is to leeward;'
          },
          {
            id: '12-a-iii',
            title: '(iii)',
            description:
              'if a vessel with the wind on the port side sees a vessel to windward and cannot determine with certainty whether the other vessel has the wind on the port or on the starboard side, she shall keep out of the way of the other.'
          }
        ]
      },
      {
        id: '12-b',
        title: '(b)',
        description:
          'For the purposes of this Rule the windward side shall be deemed to be the side opposite to that on which the mainsail is carried or, in the case of a square-rigged vessel, the side opposite to that on which the largest fore-and-aft sail is carried.'
      }
    ]
  },
  {
    id: '13',
    title: 'Rule 13 - Overtaking',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another',
    subRules: [
      {
        id: '13-a',
        title: '(a)',
        description:
          'Notwithstanding anything contained in the Rules of Part B, Sections I and II, any vessel overtaking any other shall keep out of the way of the vessel being overtaken.'
      },
      {
        id: '13-b',
        title: '(b)',
        description:
          'A vessel shall be deemed to be overtaking when coming up with another vessel from a direction more than 22.5 degrees abaft her beam, that is, in such a position with reference to the vessel she is overtaking, that at night she would be able to see only the stern light of that vessel but neither of her sidelights.'
      },
      {
        id: '13-c',
        title: '(c)',
        description:
          'When a vessel is in any doubt as to whether she is overtaking another, she shall assume that this is the case and act accordingly.'
      },
      {
        id: '13-d',
        title: '(d)',
        description:
          'Any subsequent alteration of the bearing between the two vessels shall not make the overtaking vessel a crossing vessel within the meaning of these Rules or relieve her of the duty of keeping clear of the overtaken vessel until she is finally past and clear.'
      }
    ]
  },
  {
    id: '14',
    title: 'Rule 14 - Head-on situation',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another',
    subRules: [
      {
        id: '14-a',
        title: '(a)',
        description:
          'When two power-driven vessels are meeting on reciprocal or nearly reciprocal courses so as to involve risk of collision each shall alter her course to starboard so that each shall pass on the port side of the other.'
      },
      {
        id: '14-b',
        title: '(b)',
        description:
          'Such a situation shall be deemed to exist when a vessel sees the other ahead or nearly ahead and by night she would see the masthead lights of the other in a line or nearly in a line and or both sidelights and by day she observes the corresponding aspect of the other vessel.'
      },
      {
        id: '14-c',
        title: '(c)',
        description:
          'When a vessel is in any doubt as to whether such a situation exists she shall assume that it does exist and act accordingly.'
      }
    ]
  },
  {
    id: '15',
    title: 'Rule 15 - Crossing situation',
    description:
      'When two power-driven vessels are crossing so as to involve risk of collision, the vessel which has the other on her own starboard side shall keep out of the way and shall, if the circumstances of the case admit, avoid crossing ahead of the other vessel.',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another'
  },
  {
    id: '16',
    title: 'Rule 16 - Action by give-way vessel',
    description:
      'Every vessel which is directed to keep out of the way of another vessel shall, so far as possible, take early and substantial action to keep well clear.',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another'
  },
  {
    id: '17',
    title: 'Rule 17 - Action by stand-on vessel',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another',
    subRules: [
      {
        id: '17-a',
        title: '(a)',
        description: '',
        subSubRules: [
          {
            id: '17-a-i',
            title: '(i)',
            description:
              'Where one of two vessels is to keep out of the way the other shall keep her course and speed.'
          },
          {
            id: '17-a-ii',
            title: '(ii)',
            description:
              'The latter vessel may however take action to avoid collision by her manoeuvre alone, as soon as it becomes apparent to her that the vessel required to keep out of the way is not taking appropriate action in compliance with these Rules.'
          }
        ]
      },
      {
        id: '17-b',
        title: '(b)',
        description:
          'When, from any cause, the vessel required to keep her course and speed finds herself so close that collision cannot be avoided by the action of the give-way vessel alone, she shall take such action as will best aid to avoid collision.'
      },
      {
        id: '17-c',
        title: '(c)',
        description:
          'A power-driven vessel which takes action in a crossing situation in accordance with sub-paragraph (a)(ii) of this Rule to avoid collision with another power-driven vessel shall, if the circumstances of the case admit, not alter course to port for a vessel on her own port side.'
      },
      {
        id: '17-d',
        title: '(d)',
        description:
          'This Rule does not relieve the give-way vessel of her obligation to keep out of the way'
      }
    ]
  },
  {
    id: '18',
    title: 'Rule 18 - Responsibilities between vessels',
    description: 'Except where Rules 9, 10, and 13 otherwise require:',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section II Conduct of vessels in sight of one another',
    subRules: [
      {
        id: '18-a',
        title: '(a)',
        description:
          'A power-driven vessel underway shall keep out of the way of:',
        subSubRules: [
          {
            id: '18-a-i',
            title: '(i)',
            description: 'a vessel not under command;'
          },
          {
            id: '18-a-ii',
            title: '(ii)',
            description: 'a vessel restricted in her ability to manoeuvre'
          },
          {
            id: '18-a-iii',
            title: '(iii)',
            description: 'a vessel engaged in fishing;'
          },
          {
            id: '18-a-iv',
            title: '(iv)',
            description: 'a sailing vessel.'
          }
        ]
      },
      {
        id: '18-b',
        title: '(b)',
        description: 'A sailing vessel underway shall keep out of the way of:',
        subSubRules: [
          {
            id: '18-b-i',
            title: '(i)',
            description: 'a vessel not under command;'
          },
          {
            id: '18-b-ii',
            title: '(ii)',
            description: 'a vessel restricted in her ability to manoeuvre'
          },
          {
            id: '18-b-iii',
            title: '(iii)',
            description: 'a vessel engaged in fishing;'
          }
        ]
      },
      {
        id: '18-c',
        title: '(c)',
        description:
          'A vessel engaged in fishing when underway shall, so far as possible, keep out of the way of:',
        subSubRules: [
          {
            id: '18-c-i',
            title: '(i)',
            description: 'a vessel not under command;'
          },
          {
            id: '18-c-ii',
            title: '(ii)',
            description: 'a vessel restricted in her ability to manoeuvre'
          }
        ]
      },
      {
        id: '18-d',
        title: '(d)',
        description: '',
        subSubRules: [
          {
            id: '18-d-i',
            title: '(i)',
            description:
              'Any vessel other than a vessel not under command or a vessel restricted in her ability to manoeuvre shall, if the circumstances of the case admit, avoid impeding the safe passage of a vessel constrained by her draught, exhibiting the signals in Rule 28.'
          },
          {
            id: '18-d-ii',
            title: '(ii)',
            description:
              'A vessel constrained by her draught shall navigate with particular caution having full regard to her special condition.'
          }
        ]
      },
      {
        id: '18-e',
        title: '(e)',
        description:
          'A seaplane on the water shall, in general, keep well clear of all vessels and avoid impeding their navigation. In circumstances, however, where risk of collision exists, she shall comply with the Rules of this Part.'
      },
      {
        id: '18-f',
        title: '(f)',
        description: '',
        subSubRules: [
          {
            id: '18-f-i',
            title: '(i)',
            description:
              'A WIG craft shall, when taking off, landing and in flight near the surface, keep well clear of all other vessels and avoid impeding their navigation;'
          },
          {
            id: '18-f-ii',
            title: '(ii)',
            description:
              'A WIG craft operating on the water surface shall comply with the Rules of this Part as a power-driven vessel.'
          }
        ]
      }
    ]
  },
  {
    id: '19',
    title: 'Rule 19 - Conduct of vessels in restricted visibility',
    description: '',
    part: 'Part B - Steering & Sailing Rules',
    section: 'Section III Conduct of vessels in restricted visibility',
    subRules: [
      {
        id: '19-a',
        title: '(a)',
        description:
          'This Rule applies to vessels not in sight of one another when navigating in or near an area of restricted visibility.'
      },
      {
        id: '19-b',
        title: '(b)',
        description:
          'Every vessel shall proceed at a safe speed adapted to the prevailing circumstances and conditions of restricted visibility. A power-driven vessel shall have her engines ready for immediate manoeuvre.'
      },
      {
        id: '19-c',
        title: '(c)',
        description:
          'Every vessel shall have due regard to the prevailing circumstances and conditions of restricted visibility when complying with the Rules of Section I of this Part.'
      },
      {
        id: '19-d',
        title: '(d)',
        description: '',
        subSubRules: [
          {
            id: '19-d-intro',
            title: 'Intro',
            description:
              'A vessel which detects by radar alone the presence of another vessel shall determine if a close-quarters situation is developing and/or risk of collision exists. If so, she shall take avoiding action in ample time, provided that when such action consists of an alteration of course, so far as possible the following shall be avoided:'
          },
          {
            id: '19-d-i',
            title: '(i)',
            description:
              'an alteration of course to port for a vessel forward of the beam, other than for a vessel being overtaken;'
          },
          {
            id: '19-d-ii',
            title: '(ii)',
            description:
              'an alteration of course towards a vessel abeam or abaft the beam.'
          }
        ]
      },
      {
        id: '19-e',
        title: '(e)',
        description:
          'Except where it has been determined that a risk of collision does not exist, every vessel which hears apparently forward of her beam the fog signal of another vessel, or which cannot avoid a close-quarters situation with another vessel forward of her beam, shall reduce her speed to the minimum at which she can be kept on her course. She shall if necessary take all her way off and in any event navigate with extreme caution until danger of collision is over.'
      }
    ]
  },
  {
    id: '20',
    title: 'Rule 20 - Application',
    description: 'This is rule 20 - application.',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '20-a',
        title: '(a)',
        description:
          'Rules in this Part shall be complied with in all weathers.'
      },
      {
        id: '20-b',
        title: '(b)',
        description:
          'The Rules concerning lights shall be complied with from sunset to sunrise, and during such times no other lights shall be exhibited, except such lights as cannot be mistaken for the lights specified in these Rules or do not impair their visibility or distinctive character, or interfere with the keeping of a proper look-out.'
      },
      {
        id: '20-c',
        title: '(c)',
        description:
          'The lights prescribed by these Rules shall, if carried, also be exhibited from sunrise to sunset in restricted visibility and may be exhibited in all other circumstances when it is deemed necessary.'
      },
      {
        id: '20-d',
        title: '(d)',
        description:
          'The Rules concerning shapes shall be complied with by day.'
      },
      {
        id: '20-e',
        title: '(e)',
        description:
          'The lights and shapes specified in these Rules shall comply with the provisions of Annex I to these Regulations.'
      }
    ]
  },
  {
    id: '21',
    title: 'Rule 21 - Definitions',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '21-a',
        title: '(a)',
        description:
          '“Masthead light” means a white light placed over the fore and aft centreline of the vessel showing an unbroken light over an arc of the horizon of 225 degrees and so fixed as to show the light from right ahead to 22.5 degrees abaft the beam on either side of the vessel.'
      },
      {
        id: '21-b',
        title: '(b)',
        description:
          '“Sidelights” means a green light on the starboard side and a red light on the port side each showing an unbroken light over an arc of the horizon of 112.5 degrees and so fixed as to show the light from right ahead to 22.5 degrees abaft the beam on its respective side. In a vessel of less than 20 meters in length the sidelights may be combined in one lantern carried on the fore and aft centerline of the vessel.'
      },
      {
        id: '21-c',
        title: '(c)',
        description:
          '“Sternlight” means a white light placed as nearly as practicable at the stern showing an unbroken light over an arc of the horizon of 135 degrees and so fixed as to show the light 67.5 degrees from right aft on each side of the vessel.'
      },
      {
        id: '21-d',
        title: '(d)',
        description:
          '“Towing light” means a yellow light having the same characteristics as the “sternlight” defined in paragraph (c) of this Rule.'
      },
      {
        id: '21-e',
        title: '(e)',
        description:
          '“All-round light” means a light showing an unbroken light over an arc of the horizon of 360 degrees.'
      },
      {
        id: '21-f',
        title: '(f)',
        description:
          '“Flashing light” means a light flashing at regular intervals at a frequency of 120 flashes or more per minute.'
      }
    ]
  },
  {
    id: '22',
    title: 'Rule 22 - Visibility of Lights',
    description:
      'The lights prescribed in these Rules shall have an intensity as specified in Section 8 of Annex I to these Regulations so as to be visible at the following minimum ranges:',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '22-a',
        title: '(a)',
        description: 'In vessels of 50 meters or more in length:',
        subSubRules: [
          {
            id: '22-a-i',
            title: '(i)',
            description: 'a masthead light, 6 miles;'
          },
          {
            id: '22-a-ii',
            title: '(ii)',
            description: 'a sidelight, 3 miles;'
          },
          {
            id: '22-a-iii',
            title: '(iii)',
            description: 'a sternlight, 3 miles;'
          },
          {
            id: '22-a-iv',
            title: '(iv)',
            description: 'a towing light, 3 miles;'
          },
          {
            id: '22-a-v',
            title: '(v)',
            description:
              'a white, red, green or yellow all-round light, 3 miles.'
          }
        ]
      },

      {
        id: '22-b',
        title: '(b)',
        description:
          'In vessels of 12 meters or more in length but less than 50 meters in length:',
        subSubRules: [
          {
            id: '22-b-i',
            title: '(i)',
            description:
              'a masthead light, 5 miles; except that where the length of the vessel is less than 20 meters, 3 miles;'
          },
          {
            id: '22-b-ii',
            title: '(ii)',
            description: 'a sidelight, 2 miles;'
          },
          {
            id: '22-b-iii',
            title: '(iii)',
            description: 'a sternlight, 2 miles;'
          },
          {
            id: '22-b-iv',
            title: '(iv)',
            description: 'a towing light, 2 miles;'
          },
          {
            id: '22-b-v',
            title: '(v)',
            description:
              'a white, red, green or yellow all-round light, 2 miles.'
          }
        ]
      },

      {
        id: '22-c',
        title: '(c)',
        description: 'In vessels of less than 12 meters in length:',
        subSubRules: [
          {
            id: '22-c-i',
            title: '(i)',
            description: 'a masthead light, 2 miles;'
          },
          {
            id: '22-c-ii',
            title: '(ii)',
            description: 'a sidelight, 1 mile;'
          },
          {
            id: '22-c-iii',
            title: '(iii)',
            description: 'a sternlight, 2 miles;'
          },
          {
            id: '22-c-iv',
            title: '(iv)',
            description: 'a towing light, 2 miles;'
          },
          {
            id: '22-c-v',
            title: '(v)',
            description:
              'a white, red, green or yellow all-round light, 2 miles.'
          }
        ]
      },

      {
        id: '22-d',
        title: '(d)',
        description:
          'In inconspicuous, partly submerged vessels or objects being towed:',
        subSubRules: [
          {
            id: '22-d-i',
            title: '(i)',
            description: 'a white all-round light, 3 miles.'
          }
        ]
      }
    ]
  },
  {
    id: '23',
    title: 'Rule 23 - Power-driven Vessels Underway',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '23-a',
        title: '(a)',
        description: 'A power-driven vessel underway shall exhibit:',
        subSubRules: [
          {
            id: '23-a-i',
            title: '(i)',
            description: 'a masthead light forward;'
          },
          {
            id: '23-a-ii',
            title: '(ii)',
            description:
              'a second masthead light abaft of and higher than the forward one; except that a vessel of less than 50 meters in length shall not be obliged to exhibit such light but may do so;'
          },
          {
            id: '23-a-iii',
            title: '(iii)',
            description: 'sidelights; and'
          },
          {
            id: '23-a-iv',
            title: '(iv)',
            description: 'a sternlight.'
          }
        ]
      },

      {
        id: '23-b',
        title: '(b)',
        description:
          'An air-cushion vessel when operating in the non-displacement mode shall, in addition to the lights prescribed in paragraph (a) of this Rule, exhibit an all-round flashing yellow light.'
      },
      {
        id: '23-c',
        title: '(c)',
        description:
          'A WIG craft only when taking off, landing and in flight near the surface shall, in addition to the lights prescribed in paragraph (a) of this Rule, exhibit a high intensity all-round flashing red light.'
      },
      {
        id: '23-d',
        title: '(d)',
        description: '',
        subSubRules: [
          {
            id: '23-d-i',
            title: '(i)',
            description:
              'A power-driven vessel of less than 12 meters in length may in lieu of the lights prescribed in paragraph (a) of this Rule exhibit an all-round white light and sidelights;'
          },
          {
            id: '23-d-ii',
            title: '(ii)',
            description:
              'a power-driven vessel of less than 7 meters in length whose maximum speed does not exceed 7 knots may in lieu of the lights prescribed in paragraph (a) of this Rule exhibit an all-round white light and shall, if practicable, also exhibit sidelights;'
          },
          {
            id: '23-d-iii',
            title: '(iii)',
            description:
              'the masthead light or all-round white light on a power-driven vessel of less than 12 meters in length may be displaced from the fore and aft centerline of the vessel if centerline fitting is not practicable, provided that the sidelights are combined in one lantern which shall be carried on the fore and aft centerline of the vessel or located as nearly as practicable in the same fore and aft line as the masthead light or the all-round white light.'
          }
        ]
      }
    ]
  },
  {
    id: '24',
    title: 'Rule 24 - Towing and Pushing',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '24-a',
        title: '(a)',
        description: 'A power-driven vessel when towing shall exhibit:',
        subSubRules: [
          {
            id: '24-a-i',
            title: '(i)',
            description:
              'instead of the light prescribed in Rule 23(a)(i) or (a)(ii), two masthead lights in a vertical line. When the length of the tow, measuring from the stern of the towing vessel to the after end of the tow exceeds 200 meters, three such lights in a vertical line;'
          },
          {
            id: '24-a-ii',
            title: '(ii)',
            description: 'sidelights'
          },
          {
            id: '24-a-iii',
            title: '(iii)',
            description: 'a sternlight;'
          },
          {
            id: '24-a-iv',
            title: '(iv)',
            description:
              'a towing light in a vertical line above the sternlight;'
          },
          {
            id: '24-a-v',
            title: '(v)',
            description:
              'when the length of the tow exceeds 200 meters, a diamond shape where it can best be seen.'
          }
        ]
      },

      {
        id: '24-b',
        title: '(b)',
        description:
          'When a pushing vessel and a vessel being pushed ahead are rigidly connected in a composite unit they shall be regarded as a power-driven vessel and exhibit the lights prescribed in Rule 23.'
      },
      {
        id: '24-c',
        title: '(c)',
        description:
          'A power-driven vessel when pushing ahead or towing alongside, except in the case of a composite unit, shall exhibit:',
        subSubRules: [
          {
            id: '24-c-i',
            title: '(i)',
            description:
              'instead of the light prescribed in Rule 23(a)(i) or 23(a)(ii), two masthead lights in a vertical line;'
          },
          {
            id: '24-c-ii',
            title: '(ii)',
            description: 'sidelights; and'
          },
          {
            id: '24-c-iii',
            title: '(iii)',
            description: 'a sternlight.'
          }
        ]
      },

      {
        id: '24-d',
        title: '(d)',
        description:
          'A power-driven vessel to which paragraph (a) or (c) of this Rule apply shall also comply with Rule 23(a)(ii).'
      },
      {
        id: '24-e',
        title: '(e)',
        description:
          'A vessel or object being towed, other than those mentioned in paragraph (g) of this Rule, shall exhibit:',
        subSubRules: [
          {
            id: '24-e-i',
            title: '(i)',
            description: 'sidelights;'
          },
          {
            id: '24-e-ii',
            title: '(ii)',
            description: 'a sternlight;'
          },
          {
            id: '24-e-iii',
            title: '(iii)',
            description:
              'when the length of the tow exceeds 200 meters, a diamond shape where it can best be seen.'
          }
        ]
      },

      {
        id: '24-f',
        title: '(f)',
        description:
          'Provided that any number of vessels being towed alongside or pushed in a group shall be lighted as one vessel:',
        subSubRules: [
          {
            id: '24-f-i',
            title: '(i)',
            description:
              'a vessel being pushed ahead, not being part of a composite unit, shall exhibit at the forward end, sidelights;'
          },
          {
            id: '24-f-ii',
            title: '(ii)',
            description:
              'a vessel being towed alongside shall exhibit a sternlight and at the forward end, sidelights.'
          },
          {
            id: '24-f-iii',
            title: '(iii)',
            description:
              'when the length of the tow exceeds 200 meters, a diamond shape where it can best be seen.'
          }
        ]
      },

      {
        id: '24-g',
        title: '(g)',
        description:
          'An inconspicuous, partly submerged vessel or object, or combination of such vessels or objects being towed, shall exhibit:',
        subSubRules: [
          {
            id: '24-g-i',
            title: '(i)',
            description:
              'if it is less than 25 meters in breadth, one all-round white light at or near the forward end and one at or near the after end except that dracones need not exhibit a light at or near the forward end;'
          },
          {
            id: '24-g-ii',
            title: '(ii)',
            description:
              'if it is 25 meters or more in breadth, two additional all-round white lights at or near the extremities of its breadth;'
          },
          {
            id: '24-g-iii',
            title: '(iii)',
            description:
              'if it exceeds 100 meters in length, additional all-round white lights between the lights prescribed in subparagraphs (i) and (ii) so that the distance between the lights shall not exceed 100 meters;'
          },
          {
            id: '24-g-iv',
            title: '(iv)',
            description:
              'a diamond shape at or near the aftermost extremity of the last vessel or object being towed and if the length of the tow exceeds 200 meters an additional diamond shape where it can best be seen and located as far forward as is practicable.'
          }
        ]
      },

      {
        id: '24-h',
        title: '(h)',
        description:
          'Where from any sufficient cause it is impracticable for a vessel or object being towed to exhibit the lights or shapes prescribed in paragraph (e) or (g) of this Rule, all possible measures shall be taken to light the vessel or object towed or at least to indicate the presence of such vessel or object.'
      },
      {
        id: '24-i',
        title: '(i)',
        description:
          'Where from any sufficient cause it is impracticable for a vessel not normally engaged in towing operations to display the lights prescribed in paragraph (a) or (c) of this Rule, such vessel shall not be required to exhibit those lights when engaged in towing another vessel in distress or otherwise in need of assistance. All possible measures shall be taken to indicate the nature of the relationship between the towing vessel and the vessel being towed as authorized by Rule 36, in particular by illuminating the towline.'
      }
    ]
  },
  {
    id: '25',
    title: 'Rule 25 - Sailing Vessels Underway and Vessels Under Oars',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '25-a',
        title: '(a)',
        description: 'A sailing vessel underway shall exhibit:',
        subSubRules: [
          {
            id: '25-a-i',
            title: '(i)',
            description: 'sidelights;'
          },
          {
            id: '25-a-ii',
            title: '(ii)',
            description: 'a stern light.'
          }
        ]
      },

      {
        id: '25-b',
        title: '(b)',
        description:
          'In a sailing vessel of less than 20 meters in length the lights prescribed in paragraph (a) of this Rule may be combined in one lantern carried at or near the top of the mast where it can best be seen.'
      },
      {
        id: '25-c',
        title: '(c)',
        description:
          'A sailing vessel underway may, in addition to the lights prescribed in paragraph (a) of this Rule, exhibit at or near the top of the mast, where they can best be seen, two all-round lights in a vertical line, the upper being red and the lower green, but these lights shall not be exhibited in conjunction with the combined lantern permitted by paragraph (b) of this Rule.'
      },
      {
        id: '25-d',
        title: '(d)',
        description: '',
        subSubRules: [
          {
            id: '25-d-i',
            title: '(i)',
            description:
              'A sailing vessel of less than 7 meters in length shall, if practicable, exhibit the lights prescribed in paragraph (a) or (b) of this Rule, but if she does not, she shall have ready at hand an electric torch or lighted lantern showing a white light which shall be exhibited in sufficient time to prevent collision.'
          },
          {
            id: '25-d-ii',
            title: '(ii)',
            description:
              'A vessel under oars may exhibit the lights prescribed in this Rule for sailing vessels, but if she does not, she shall have ready at hand an electric torch or lighted lantern showing a white light which shall be exhibited in sufficient time to prevent collision.'
          }
        ]
      },

      {
        id: '25-e',
        title: '(e)',
        description:
          'A vessel proceeding under sail when also being propelled by machinery shall exhibit forward where it can best be seen a conical shape, apex downwards.'
      }
    ]
  },
  {
    id: '26',
    title: 'Rule 26 - Fishing Vessels',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '26-a',
        title: '(a)',
        description:
          'A vessel engaged in fishing, whether underway or at anchor, shall exhibit only the lights and shapes prescribed in this Rule.'
      },
      {
        id: '26-b',
        title: '(b)',
        description:
          'A vessel when engaged in trawling, by which is meant the dragging through the water of a dredge net or other apparatus used as a fishing appliance, shall exhibit:',
        subSubRules: [
          {
            id: '26-b-i',
            title: '(i)',
            description:
              'two all-round lights in a vertical line, the upper being green and the lower white, or a shape consisting of two cones with their apexes together in a vertical line one above the other;'
          },
          {
            id: '26-b-ii',
            title: '(ii)',
            description:
              'a masthead light abaft of and higher than the all-round green light; a vessel of less than 50 meters in length shall not be obliged to exhibit such a light but may do so; and'
          },
          {
            id: '26-b-iii',
            title: '(iii)',
            description:
              'when making way through the water, in addition to the lights prescribed in this paragraph, sidelights and a sternlight.'
          }
        ]
      },

      {
        id: '26-c',
        title: '(c)',
        description:
          'A vessel engaged in fishing, other than trawling, shall exhibit:',
        subSubRules: [
          {
            id: '26-c-i',
            title: '(i)',
            description:
              'two all-round lights in a vertical line, the upper being red and the lower white, or a shape consisting of two cones with apexes together in a vertical line one above the other;'
          },
          {
            id: '26-c-ii',
            title: '(ii)',
            description:
              'when there is outlying gear extending more than 150 meters horizontally from the vessel, an all-round white light or a cone apex upwards in the direction of the gear;'
          },
          {
            id: '26-c-iii',
            title: '(iii)',
            description:
              'when making way through the water, in addition to the lights prescribed in this paragraph, sidelights and a sternlight.'
          }
        ]
      },

      {
        id: '26-d',
        title: '(d)',
        description:
          'The additional signals described in Annex II to these Rules apply to a vessel engaged in fishing in close proximity to other vessels engaged in fishing.'
      },
      {
        id: '26-e',
        title: '(e)',
        description:
          'A vessel when not engaged in fishing shall not exhibit the lights or shapes prescribed in this Rule, but only those prescribed for a vessel of her length.'
      }
    ]
  },
  {
    id: '27',
    title: 'Rule 27 - Vessels NUC or RAM',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '27-a',
        title: '(a)',
        description: 'A vessel not under command shall exhibit:',
        subSubRules: [
          {
            id: '27-a-i',
            title: '(i)',
            description:
              'two all-round red lights in a vertical line where they can best be seen;'
          },
          {
            id: '27-a-ii',
            title: '(ii)',
            description:
              'two balls or similar shapes in a vertical line where they can best be seen;'
          },
          {
            id: '27-a-iii',
            title: '(iii)',
            description:
              'when making way through the water, in addition to the lights prescribed in this paragraph, sidelights and a sternlight.'
          }
        ]
      },

      {
        id: '27-b',
        title: '(b)',
        description:
          'A vessel restricted in her ability to manoeuvre, except a vessel engaged in mine clearance operations, shall exhibit:',
        subSubRules: [
          {
            id: '27-b-i',
            title: '(i)',
            description:
              'three all-round lights in a vertical line where they can best be seen. The highest and lowest of these lights shall be red and the middle light shall be white;'
          },
          {
            id: '27-b-ii',
            title: '(ii)',
            description:
              'three shapes in a vertical line where they can best be seen. The highest and lowest of these shapes shall be balls and the middle one a diamond;'
          },
          {
            id: '27-b-iii',
            title: '(iii)',
            description:
              'when making way through the water, a masthead light or lights, sidelights and a sternlight, in addition to the lights prescribed in subparagraph (i);'
          },
          {
            id: '27-b-iv',
            title: '(iv)',
            description:
              'when at anchor, in addition to the lights or shapes prescribed in subparagraphs (i) and (ii), the light, lights or shape prescribed in Rule 30.'
          }
        ]
      },

      {
        id: '27-c',
        title: '(c)',
        description:
          'A power-driven vessel engaged in a towing operation such as severely restricts the towing vessel and her tow in their ability to deviate from their course shall, in addition to the lights or shapes prescribed in Rule 24(a), exhibit the lights or shapes prescribed in subparagraphs (b)(i) and (ii) of this Rule.'
      },
      {
        id: '27-d',
        title: '(d)',
        description:
          'A vessel engaged in dredging or underwater operations, when restricted in her ability to manoeuvre, shall exhibit the lights and shapes prescribed in subparagraphs (b)(i), (ii) and (iii) of this Rule and shall in addition, when an obstruction exists, exhibit:',
        subSubRules: [
          {
            id: '27-d-i',
            title: '(i)',
            description:
              'two all-round red lights or two balls in a vertical line to indicate the side on which the obstruction exists;'
          },
          {
            id: '27-d-ii',
            title: '(ii)',
            description:
              'two all-round green lights or two diamonds in a vertical line to indicate the side on which another vessel may pass;'
          },
          {
            id: '27-d-iii',
            title: '(iii)',
            description:
              'when at anchor, the lights or shapes prescribed in this paragraph instead of the lights or shape prescribed in Rule 30.'
          }
        ]
      },

      {
        id: '27-e',
        title: '(e)',
        description:
          'Whenever the size of a vessel engaged in diving operations makes it impracticable to exhibit all lights and shapes prescribed in paragraph (d) of this Rule, the following shall be exhibited:',
        subSubRules: [
          {
            id: '27-e-i',
            title: '(i)',
            description:
              'three all-round lights in a vertical line where they can best be seen. The highest and lowest of these lights shall be red and the middle light shall be white;'
          },
          {
            id: '27-e-ii',
            title: '(ii)',
            description:
              'a rigid replica of the International Code flag “A” not less than 1 meter in height. Measures shall be taken to ensure its all-round visibility.'
          }
        ]
      },

      {
        id: '27-f',
        title: '(f)',
        description:
          'A vessel engaged in mine clearance operations shall, in addition to the lights prescribed for a power-driven vessel in Rule 23 or to the lights or shape prescribed for a vessel at anchor in Rule 30 as appropriate, exhibit three all-round green lights or three balls. One of these lights or shapes shall be exhibited near the foremast head and one at each end of the fore yard. These lights or shapes indicate that it is dangerous for another vessel to approach within 1000 meters of the mine clearance vessel.'
      },
      {
        id: '27-g',
        title: '(g)',
        description:
          'Vessels of less than 12 meters in length, except those engaged in diving operations, shall not be required to exhibit the lights and shapes prescribed in this Rule.'
      },
      {
        id: '27-h',
        title: '(h)',
        description:
          'The signals prescribed in this Rule are not signals of vessels in distress and requiring assistance. Such signals are contained in Annex IV to these Regulations.'
      }
    ]
  },
  {
    id: '28',
    title: 'Rule 28 - Vessels Constrained by Their Draft',
    description:
      'A vessel constrained by her draft may, in addition to the lights prescribed for power-driven vessels in Rule 23, exhibit where they can best be seen three all-round red lights in a vertical line, or a cylinder.',
    part: 'Part C - Lights and Shapes'
  },
  {
    id: '29',
    title: 'Rule 29 - Pilot Vessels',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '29-a',
        title: '(a)',
        description: 'A vessel engaged on pilotage duty shall exhibit:',
        subSubRules: [
          {
            id: '29-a-i',
            title: '(i)',
            description:
              'at or near the masthead, two all-round lights in a vertical line, the upper being white and the lower red;'
          },
          {
            id: '29-a-ii',
            title: '(ii)',
            description:
              'when underway, in addition, sidelights and a sternlight;'
          },
          {
            id: '29-a-iii',
            title: '(iii)',
            description:
              'when at anchor, in addition to the lights prescribed in subparagraph (i), the light, lights or shape prescribed in Rule 30 for vessels at anchor.'
          }
        ]
      },
      {
        id: '29-b',
        title: '(b)',
        description:
          'A pilot vessel when not engaged on pilotage duty shall exhibit the lights or shapes prescribed for a similar vessel of her length.'
      }
    ]
  },
  {
    id: '30',
    title: 'Rule 30 - Anchored Vessels and Vessels Aground',
    description: '',
    part: 'Part C - Lights and Shapes',
    subRules: [
      {
        id: '30-a',
        title: '(a)',
        description:
          'A vessel at anchor shall exhibit where it can best be seen:',
        subSubRules: [
          {
            id: '30-a-i',
            title: '(i)',
            description:
              'in the fore part, an all-round white light or one ball;'
          },
          {
            id: '30-a-ii',
            title: '(ii)',
            description:
              'at or near the stern and at a lower level than the light prescribed in subparagraph (i), an all-round white light.'
          }
        ]
      },

      {
        id: '30-b',
        title: '(b)',
        description:
          'A vessel of less than 50 meters in length may exhibit an all-round white light where it can best be seen instead of the lights prescribed in paragraph (a) of this Rule.'
      },
      {
        id: '30-c',
        title: '(c)',
        description:
          'A vessel at anchor may, and a vessel of 100 meters and more in length shall, also use the available working or equivalent lights to illuminate her decks.'
      },
      {
        id: '30-d',
        title: '(d)',
        description:
          'A vessel aground shall exhibit the lights prescribed in paragraph (a) or (b) of this Rule and in addition, where they can best be seen:',
        subSubRules: [
          {
            id: '30-d-i',
            title: '(i)',
            description: 'two all-round red lights in a vertical line;'
          },
          {
            id: '30-d-ii',
            title: '(ii)',
            description: 'three balls in a vertical line.'
          }
        ]
      },

      {
        id: '30-e',
        title: '(e)',
        description:
          'A vessel of less than 7 meters in length, when at anchor, not in or near a narrow channel, fairway or anchorage, or where other vessels normally navigate, shall not be required to exhibit the lights or shape prescribed in paragraphs (a) and (b) of this Rule. (f) A vessel of less than 12 meters in length, when aground, shall not be required to exhibit the lights or shapes prescribed in subparagraphs (d)(i) and (ii) of this Rule.'
      },
      {
        id: '30-f',
        title: '(f)',
        description:
          'A vessel of less than 12 meters in length when aground shall not be required to exhibit the lights or shapes prescribed in subparagraphs (d)(i) and (ii) of this Rule.'
      }
    ]
  },
  {
    id: '31',
    title: 'Rule 31 - Seaplanes and WIG craft',
    description:
      'Where it is impracticable for a seaplane or a WIG craft to exhibit lights and shapes of the characteristics or in the positions prescribed in the Rules of this Part she shall exhibit lights and shapes as closely similar in characteristics and position as is possible.',
    part: 'Part C - Lights and Shapes'
  },
  {
    id: '32',
    title: 'Rule 32 - Definitions',
    description: '',
    part: 'Part D - Sound & Light Signals',
    subRules: [
      {
        id: '32-a',
        title: '(a)',
        description:
          'The word “whistle” means any sound signaling appliance capable of producing the prescribed blasts and which complies with the specifications in Annex III to these Regulations.'
      },
      {
        id: '32-b',
        title: '(b)',
        description:
          'The term “short blast” means a blast of about one second’s duration.'
      },
      {
        id: '32-c',
        title: '(c)',
        description:
          'The term “prolonged blast” means a blast of from four to six seconds’ duration.'
      }
    ]
  },
  {
    id: '33',
    title: 'Rule 33 - Equipment for Sound Signals',
    description: '',
    part: 'Part D - Sound & Light Signals',
    subRules: [
      {
        id: '33-a',
        title: '(a)',
        description:
          'A vessel of 12 meters or more in length shall be provided with a whistle, a vessel of 20 meters or more in length shall be provided with a bell in addition to a whistle, and a vessel of 100 meters or more in length shall, in addition, be provided with a gong, the tone and sound of which cannot be confused with that of the bell. The whistle, bell and gong shall comply with the specifications in Annex III to these Regulations. The bell or gong or both may be replaced by other equipment having the same respective sound characteristics, provided that manual sounding of the prescribed signals shall always be possible.'
      },
      {
        id: '33-b',
        title: '(b)',
        description:
          'A vessel of less than 12 meters in length shall not be obliged to carry the sound signaling appliances prescribed in paragraph (a) of this Rule but if she does not, she shall be provided with some other means of making an efficient sound signal.'
      }
    ]
  },
  {
    id: '34',
    title: 'Rule 34 - Manoeuvring and Warning Signals',
    description: '',
    part: 'Part D - Sound & Light Signals',
    subRules: [
      {
        id: '34-a',
        title: '(a)',
        description:
          'When vessels are in sight of one another, a power-driven vessel underway, when manoeuvring as authorized or required by these Rules, shall indicate that manoeuvre by the following signals on her whistle:',
        subSubRules: [
          {
            id: '34-a-i',
            title: '(i)',
            description:
              'one short blast to mean “I am altering my course to starboard”;'
          },
          {
            id: '34-a-ii',
            title: '(ii)',
            description:
              'two short blasts to mean “I am altering my course to port”;'
          },
          {
            id: '34-a-iii',
            title: '(iii)',
            description:
              'three short blasts to mean “I am operating astern propulsion”.'
          }
        ]
      },

      {
        id: '34-b',
        title: '(b)',
        description:
          'Any vessel may supplement the whistle signals prescribed in paragraph (a) of this Rule by light signals, repeated as appropriate, while the manoeuvre is being carried out:',
        subSubRules: [
          {
            id: '34-b-i',
            title: '(i)',
            description:
              'these light signals shall have the following significance: - one flash to mean “I am altering my course to starboard”; − two flashes to mean “I am altering my course to port”; − two flashes to mean “I am altering my course to port”;'
          },
          {
            id: '34-b-ii',
            title: '(ii)',
            description:
              'the duration of each flash shall be about one second, the interval between flashes shall be about one second, and the interval between successive signals shall be not less than ten seconds;'
          },
          {
            id: '34-b-iii',
            title: '(iii)',
            description:
              'the light used for this signal shall, if fitted, be an all-round white light, visible at a minimum range of 5 miles, and shall comply with the provisions of Annex I to these Regulations.'
          }
        ]
      },

      {
        id: '34-c',
        title: '(c)',
        description:
          'When in sight of one another in a narrow channel or fairway:',
        subSubRules: [
          {
            id: '34-c-i',
            title: '(i)',
            description:
              'a vessel intending to overtake another shall in compliance with Rule 9(e)(i) indicate her intention by the following signals on her whistle: − two prolonged blasts followed by one short blast to mean “I intend to overtake you on your starboard side”; − two prolonged blasts followed by two short blasts to mean “I intend to overtake you on your port side”.'
          },
          {
            id: '34-c-ii',
            title: '(ii)',
            description:
              'the vessel about to be overtaken when acting in accordance with Rule 9(e)(i) shall indicate her agreement by the following signal on her whistle: − one prolonged, one short, one prolonged and one short blast, in that order.'
          }
        ]
      },
      {
        id: '34-d',
        title: '(d)',
        description:
          'When vessels in sight of one another are approaching each other and from any cause either vessel fails to understand the intentions or actions of the other, or is in doubt whether sufficient action is being taken by the other to avoid collision, the vessel in doubt shall immediately indicate such doubt by giving at least five short and rapid blasts on the whistle. Such signal may be supplemented by a light signal of at least five short and rapid flashes.'
      },
      {
        id: '34-e',
        title: '(e)',
        description:
          'A vessel nearing a bend or an area of a channel or fairway where other vessels may be obscured by an intervening obstruction shall sound one prolonged blast. Such signal shall be answered with a prolonged blast by any approaching vessel that may be within hearing around the bend or behind the intervening obstruction.'
      },
      {
        id: '34-f',
        title: '(f)',
        description:
          'If whistles are fitted on a vessel at a distance apart of more than 100 meters, one whistle only shall be used for giving manoeuvring and warning signals.'
      }
    ]
  },
  {
    id: '35',
    title: 'Rule 35 - Sound Signals in Restricted Visibility',
    description:
      'In or near an area of restricted visibility, whether by day or night, the signals prescribed in this Rule shall be used as follows:',
    part: 'Part D - Sound & Light Signals',
    subRules: [
      {
        id: '35-a',
        title: '(a)',
        description:
          'A power-driven vessel making way through the water shall sound at intervals of not more than 2 minutes one prolonged blast.'
      },
      {
        id: '35-b',
        title: '(b)',
        description:
          'A power-driven vessel underway but stopped and making no way through the water shall sound at intervals of not more than 2 minutes two prolonged blasts in succession with an interval of about 2 seconds between them.'
      },
      {
        id: '35-c',
        title: '(c)',
        description:
          'A vessel not under command, a vessel restricted in her ability to manoeuvre, a vessel constrained by her draft, a sailing vessel, a vessel engaged in fishing and a vessel engaged in towing or pushing another vessel shall, instead of the signals prescribed in paragraphs (a) or (b) of this Rule, sound at intervals of not more than 2 minutes three blasts in succession, namely one prolonged followed by two short blasts.'
      },
      {
        id: '35-d',
        title: '(d)',
        description:
          'A vessel engaged in fishing, when at anchor, and a vessel restricted in her ability to manoeuvre when carrying out her work at anchor, shall instead of the signals prescribed in paragraph (g) of this Rule sound the signal prescribed in paragraph (c) of this Rule.'
      },
      {
        id: '35-e',
        title: '(e)',
        description:
          'A vessel towed or if more than one vessel is towed the last vessel of the tow, if manned, shall at intervals of not more than 2 minutes sound four blasts in succession, namely one prolonged followed by three short blasts. When practicable, this signal shall be made immediately after the signal made by the towing vessel.'
      },
      {
        id: '35-f',
        title: '(f)',
        description:
          'When a pushing vessel and a vessel being pushed ahead are rigidly connected in a composite unit they shall be regarded as a power-driven vessel and shall give the signals prescribed in paragraphs (a) or (b) of this Rule.'
      },
      {
        id: '35-g',
        title: '(g)',
        description:
          'A vessel at anchor shall at intervals of not more than one minute ring the bell rapidly for about 5 seconds. In a vessel of 100 meters or more in length the bell shall be sounded in the forepart of the vessel and immediately after the ringing of the bell the gong shall be sounded rapidly for about 5 seconds in the after part of the vessel. A vessel at anchor may in addition sound three blasts in succession, namely one short, one prolonged and one short blast, to give warning of her position and of the possibility of collision to an approaching vessel.'
      },
      {
        id: '35-h',
        title: '(h)',
        description:
          'A vessel aground shall give the bell signal and if required the gong signal prescribed in paragraph (g) of this Rule and shall, in addition, give three separate and distinct strokes on the bell immediately before and after the rapid ringing of the bell. A vessel aground may in addition sound an appropriate whistle signal.'
      },
      {
        id: '35-i',
        title: '(i)',
        description:
          'A vessel of 12 meters or more but less than 20 meters in length shall not be obliged to give the bell signals prescribed in paragraphs (g) and (h) of this Rule. However, if she does not, she shall make some other efficient sound signal at intervals of not more than 2 minutes.'
      },
      {
        id: '35-j',
        title: '(j)',
        description:
          'A vessel of less than 12 meters in length shall not be obliged to give the above-mentioned signals but, if she does not, shall make some other efficient sound signal at intervals of not more than 2 minutes.'
      },
      {
        id: '35-k',
        title: '(k)',
        description:
          'A pilot vessel when engaged on pilotage duty may in addition to the signals prescribed in paragraphs (a), (b) or (g) of this Rule sound an identity signal consisting of four short blasts.'
      }
    ]
  },
  {
    id: '36',
    title: 'Rule 36 - Signals to Attract Attention',
    description:
      'If necessary to attract the attention of another vessel any vessel may make light or sound signals that cannot be mistaken for any signal authorised elsewhere in these Rules, or may direct the beam of her searchlight in the direction of the danger, in such a way as not to embarrass any vessel. Any light to attract attention of another vessel shall be such that it cannot be mistaken for any aid to navigation. For the purpose of this Rule the use of high-intensity intermittent or revolving lights, such as strobe lights, shall be avoided.',
    part: 'Part D - Sound & Light Signals'
  },
  {
    id: '37',
    title: 'Rule 37 - Distress Signals',
    description:
      'When a vessel is in distress and requires assistance she shall use or exhibit the signals described in Annex IV to these Regulations.',
    part: 'Part D - Sound & Light Signals'
  },
  {
    id: 'a-4-1',
    title: 'Distress Signals - 1',
    description:
      'The following signals, used or exhibited either together or separately, indicate distress and need of assistance:',
    part: 'Annex IV - Distress Signals',
    subRules: [
      {
        id: 'a-4-1-a',
        title: '(a)',
        description:
          'a gun or other explosive signal fired at intervals of about a minute;'
      },
      {
        id: 'a-4-1-b',
        title: '(b)',
        description: 'continuous sounding with any fog-signal;'
      },
      {
        id: 'a-4-1-c',
        title: '(c)',
        description:
          'rockets or shells, throwing red stars fired one at a time at short intervals;'
      },
      {
        id: 'a-4-1-d',
        title: '(d)',
        description:
          'signals made by any signalling method consisting of the group ..._... (SOS) in morse code;'
      },
      {
        id: 'a-4-1-e',
        title: '(e)',
        description:
          'a signal sent by radiotelephony consisting of the spoken word "MAYDAY";'
      },
      {
        id: 'a-4-1-f',
        title: '(f)',
        description:
          'the International Code Signal of distress indicated by N.C.;'
      },
      {
        id: 'a-4-1-g',
        title: '(g)',
        description:
          'a signal consisting of a square flag having above or below it a ball or anything resembling a ball;'
      },
      {
        id: 'a-4-1-h',
        title: '(h)',
        description:
          'flames on the vessel (as from a burning tar barrel, oil barrel, etc.);'
      },
      {
        id: 'a-4-1-j',
        title: '(j)',
        description: 'a smoke signal giving off orange-coloured smoke;'
      },
      {
        id: 'a-4-1-k',
        title: '(k)',
        description:
          'slowly and repeatedly raising and lowering arms outstretched to each side;'
      },
      {
        id: 'a-4-1-l',
        title: '(l)',
        description:
          'a distress alert by means of digital selective calling (DSC) transmitted on VHF channel 70 or MF/HF on 2187.5 kHz, 8414.5 kHz, 4207.5 kHz, 6312 kHz, 12577 kHz or 1680 kHz;'
      },
      {
        id: 'a-4-1-m',
        title: '(m)',
        description:
          "a ship-to-shore distress alert transmitted by the ship's Inmarsat or other mobile satellite service provider ship earth station;"
      },
      {
        id: 'a-4-1-n',
        title: '(n)',
        description:
          'signals transmitted by emergency position-indicating radio beacons;'
      },
      {
        id: 'a-4-1-o',
        title: '(o)',
        description:
          'approved signals transmitted by radiocommunication systems, including survival craft radar transponder.'
      }
    ]
  },
  {
    id: 'a-4-2',
    title: 'Distress Signals - 2',
    description:
      'The use or exhibition of any of the foregoing signals, except for the purpose of indicating distress and need of assistance and the use of other signals which may be confused with any of the above signals, is prohibited.',
    part: 'Annex IV - Distress Signals'
  },
  {
    id: 'a-4-3',
    title: 'Distress Signals - 3',
    description:
      'Attention is drawn to the relevant sections of the International Code of Signals, the International Aeronautical and Maritime Search adn Rescue Manual, Volume III and the following signals;',
    part: 'Annex IV - Distress Signals',
    subRules: [
      {
        id: 'a-4-3-a',
        title: '(a)',
        description:
          'a piece of orange-coloured canvas with either a black square and circle or other appropriate symbol (for identification from the air)'
      },
      {
        id: 'a-4-3-b',
        title: '(b)',
        description: 'a dye marker'
      }
    ]
  }
];
