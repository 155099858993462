import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContactFormState {
  email: string;
  message: string;
}

const Help: React.FC = () => {
  const [formData, setFormData] = useState<ContactFormState>({
    email: '',
    message: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className='main-content'>
      <Box p={8} display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='h4' gutterBottom>
          Help & FAQ
        </Typography>
        <Typography variant='h6' gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              What do abbreviations like NUC, RAM, CBD, SV, PDV & VEIF mean?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              These abbreviations are commonly used in maritime navigation and
              refer to specific vessel types or conditions:
              <ul>
                <li>
                  <strong>NUC:</strong> Not Under Command
                </li>
                <li>
                  <strong>RAM:</strong> Restricted in Ability to Manoeuvre
                </li>
                <li>
                  <strong>CBD:</strong> Constrained By Draught
                </li>
                <li>
                  <strong>SV:</strong> Sailing Vessel
                </li>
                <li>
                  <strong>PDV:</strong> Power-Driven Vessel
                </li>
                <li>
                  <strong>VEIF:</strong> Vessel Engaged in Fishing
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Why can't I hear fog signals?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              You need to select the "lookout" tab to start to hear fog signals.
              If this still doesn't work make sure that the page in unmuted in
              your browsers and you volume is turned up.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>
              How do I see the details for a radar contact?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              Click or tap on a radar contact and the details will be displayed
              in the information panel.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>What do I do if an error message appears?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              If you encounter an error or are unable to complete a report, view
              a scenario, or see feedback, please click or tap the arrow at the
              bottom of the screen and complete the form. Include as much detail
              as possible, such as the time (including timezone), specific error
              details, and the action you were attempting when the error
              occurred.
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Can other players see my quiz scores?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              Only your scores for "Quizzes of the Day" will be displayed on the
              leaderboards. Your real name will not be displayed just your
              username example student 1.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>Why is there data missing from my feedback?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              If you do not provide all the required information in the report
              form, we will be unable to give you complete feedback. Please note
              that you will not receive a reminder if any section of your report
              is incomplete. To ensure comprehensive feedback every time, make
              sure to double-check that your report form is fully filled out.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            color: 'whitesmoke',
            backgroundColor: '#161A1Dff',
            marginBottom: '16px',
            width: '60%'
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: '#00FFFF',
                  fontSize: '2rem',
                  stroke: '#00FFFF',
                  strokeWidth: 1.5
                }}
              />
            }
          >
            <Typography>What do I do if I finish all my tasks?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign='left' p={1}>
              If you've completed all your assigned tasks, congratulations! Here
              are some suggestions for what to do next:
              <ul>
                <li>
                  Put your knowledge to the test by trying out new scenarios on
                  the practice/scenario page. This simulator generates a new
                  scenario every time and you can use it as much as you like.
                </li>
                <li>
                  Review your previous feedback and practice areas where you
                  struggled.
                </li>
                <li>
                  Check the quiz leaderboard to see how you compare with others
                  and aim to improve your ranking.
                </li>
              </ul>
              Remember, continuous learning and practice are key to mastering
              the Rules of the Road.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default Help;
