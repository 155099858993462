import React, { useEffect, useState } from 'react';
import { Route, useNavigate, useLocation, Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  isAuthenticated
}) => {
  const location = useLocation();

  if (!isAuthenticated) {
    // Redirect to home or login page
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
