import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Box, CircularProgress, Alert, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CompactNav from '../../../header/NavCompact';

import { getGame } from '../../../../clients/rotr-client';
import logoImageLarge from '../../../../images/logo_dark.png';
import logoImageSmall from '../../../../images/logo_dark_small.png';
import StreakScoreDisplay from './StreakScore';
import GameSectionHeader from './SectionHeader';
import GameSectionBody from './SectionBody';
import SectionCount from './SectionCount';
import HighestLeaderBoardPosition from './HighestLeaderBoardPosition';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';

const ShakeDownHome: React.FC = () => {
  const [streakScore, setStreakScore] = useState<number | null>(0);
  const [gameData, setGameData] = useState<any>(null);
  const [completedSectionsCount, setCompletedSectionsCount] = useState<
    number | null
  >(null);
  const [totalSectionsCount, setTotalSectionsCount] = useState<number | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  let sectionRefs: React.MutableRefObject<any> = useRef([]);
  const colors = ['#1286A6', '#7F63E0', '#12A675', '#244751'];

  const addStars = (response: any) => {
    response.sections.forEach((section: any) => {
      const LastStepIndex = section.steps.length - 1;
      if (section.steps[LastStepIndex].highScore) {
        const percentage =
          section.steps[LastStepIndex].highScore /
          section.steps[LastStepIndex].maxScore;
        if (percentage >= 0.9) {
          section.starCount = 3;
        } else if (percentage >= 0.8) {
          section.starCount = 2;
        } else if (percentage >= 0.7) {
          section.starCount = 1;
        } else {
          section.starCount = 0;
        }
      }
    });
    return response;
  };

  function calculateSteps(gameData: any) {
    gameData.sections.forEach((section: any) => {
      const totalSteps = section.steps.length;
      const completedSteps = section.steps.filter(
        (step: any) => step.completionStatus.completed
      ).length;

      section.totalSteps = totalSteps;
      section.completedSteps = completedSteps;
    });

    return gameData;
  }

  useEffect(() => {
    getGame()
      .then((response) => {
        response = addStars(response);
        response = calculateSteps(response);
        setGameData(response);
        if (response.streak) {
          setStreakScore(response.streak);
        }
        setCompletedSectionsCount(
          response.sections.reduce((acc: number, section: any) => {
            const isSectionCompleted = section.steps.every(
              (step: any) => step.completionStatus.completed
            );
            return acc + (isSectionCompleted ? 1 : 0);
          }, 0)
        );
        setTotalSectionsCount(response.sections.length);
      })
      .catch((error) => {
        console.error('Failed to fetch game data:', error);
        setError('Failed to fetch game data. Please try again later.');
      });
  }, []);

  const [currentLogo, setCurrentLogo] = useState(logoImageLarge);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setCurrentLogo(logoImageSmall);
      } else {
        setCurrentLogo(logoImageLarge);
      }
    };

    // Set the initial logo based on the current viewport width
    handleResize();

    // Listen for viewport changes
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // add loader circle
  if (!gameData) {
    return (
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'rgb(22, 26, 29)',
          padding: '0',
          overflow: 'auto',
          minHeight: '100vh',
          borderRadius: '0'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {' '}
          <Typography variant='h6' style={{ color: 'whitesmoke' }}>
            Loading...
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {error && <Alert severity='error'>{error}</Alert>}
        </div>
      </Paper>
    );
  }

  return (
    <Paper
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'rgb(22, 26, 29)',
        padding: '0',
        overflow: 'auto',
        minHeight: 'calc(100vh - 56px)',
        borderRadius: '0',
        position: 'relative'
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          padding: '0 24px',
          color: 'whitesmoke',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          backgroundColor: '#1d2125'
        }}
      >
        {error && <Alert severity='error'>{error}</Alert>}
        <Box
          flexGrow={0}
          flexShrink={0}
          style={{
            maxWidth: '800px',
            paddingTop: 'env(safe-area-inset-top)', // Adjust top padding for notch
            padding: '6px 12px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            top: 0,
            zIndex: 1,
            backgroundColor: 'rgb(22, 26, 29)'
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 0'
            }}
          >
            {/* Left-aligned items */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CompactNav />
            </div>

            {/* Right-aligned close icon */}
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <HighestLeaderBoardPosition
                position={gameData.highestLeaderBoardPosition}
              />
              <SectionCount
                completed={completedSectionsCount}
                total={totalSectionsCount}
              />
              <StreakScoreDisplay score={streakScore} />
            </div>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            alignItems={'center'}
          >
            {' '}
            <div
              style={{
                flexGrow: '1',
                flexShrink: '1',
                marginRight: '10px'
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: '0',
                flexShrink: '0',
                width: '45px'
              }}
            ></div>
          </Box>
        </Box>
        {/* Body */}
        <div style={{ width: '100%', marginTop: '100px' }}>
          {/* Add title and subtitle */}
          <Typography
            variant='h4'
            component='h1'
            gutterBottom
            style={{ color: 'whitesmoke', textAlign: 'center' }}
          >
            Quizzes
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
            style={{
              color: 'whitesmoke',
              textAlign: 'center',
              marginBottom: '20px'
            }}
          >
            Find quizzes for each section by clicking the down arrow
          </Typography>

          {gameData.sections.map((section: any, index: number) => (
            <div
              key={section.id}
              ref={sectionRefs.current[index]}
              style={{
                width: '100%',
                marginBottom: '20px'
              }}
            >
              <GameSectionHeader
                id={index}
                name={section.name}
                description={section.description}
                starCount={section.starCount}
                color={colors[index % colors.length]}
                steps={section.steps}
                totalSteps={section.totalSteps}
                completedSteps={section.completedSteps}
              />

              {/* <GameSectionBody
                steps={section.steps}
                starCount={section.starCount}
              /> */}
            </div>
          ))}
        </div>
        {/* footer */}
        <Box display={'flex'} flexDirection={'row'}>
          <div style={{ marginLeft: '20px' }}>
            {/* <Link
              to='/shake-down/faq'
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'whitesmoke',
                textDecoration: 'none',
                backgroundColor: '#1d2125'
              }}
            >
              <HelpOutlineOutlinedIcon />
              <span style={{ marginLeft: '8px' }}>Help</span>
            </Link> */}
          </div>
          <div style={{ marginLeft: '20px' }}>
            {/* <Link
              to='/shake-down/explanation'
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'whitesmoke',
                textDecoration: 'none',
                backgroundColor: '#1d2125'
              }}
            >
              <SportsEsportsOutlinedIcon />
              <span style={{ marginLeft: '8px' }}>Game Explanation</span>
            </Link> */}
          </div>
        </Box>
      </div>
    </Paper>
  );
};

export default ShakeDownHome;
