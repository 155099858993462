import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ListInstructorsInClass } from '../../clients/rotr-client';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Container,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Tooltip
} from '@mui/material';
import AttributionIcon from '@mui/icons-material/Attribution';
import { PermIdentity } from '@mui/icons-material';

interface UserModel {
  id: number;
  firstName: string;
  lastName: string;
  classCount: number;
}

const StyledContainer = styled(Container)({
  marginTop: '5rem',
  textAlign: 'center',
  '@media (min-width: 600px)': {
    maxWidth: '600px' // Set max width to 60px on large screens
  }
});

interface InstructorsProps {
  classId: string;
  classCode: string;
}

export default function Instructors({ classId, classCode }: InstructorsProps) {
  const [rows, setRows] = useState<UserModel[]>([]);

  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');

  const handleCloseError = () => setOpenError(false);
  const handleCloseMessage = () => setOpenMessage(false);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', flex: 1 },
    { field: 'lastName', headerName: 'Last name', minWidth: 30, flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 30,
      flex: 1,
      renderCell(params) {
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title='Copy User ID' arrow>
                <IconButton
                  size='small'
                  sx={{
                    color: 'white'
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(params.row.id);
                    setMessage('User ID copied to clipboard');
                    setOpenMessage(true);
                  }}
                >
                  <PermIdentity fontSize='small' />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    const fetchInstructorsInClass = async () => {
      try {
        const response = await ListInstructorsInClass(classId);
        const instructors = response.map((user: any) => {
          // check if the user is the currently logged in user
          return {
            id: user.Username, // cognito username which is a UUID
            lastName: user.UserAttributes.find(
              (attr: { Name: string; Value: string }) =>
                attr.Name === 'family_name'
            )?.Value,
            firstName: user.UserAttributes.find(
              (attr: { Name: string; Value: string }) =>
                attr.Name === 'given_name'
            )?.Value,
            classCount: user.classCount
          };
        });
        setRows(instructors);
      } catch (error) {
        console.error('Error fetching authenticated user:', error);
        setErrMsg('Error fetching user information: ' + error);
        setOpenError(true);
      }
    };

    fetchInstructorsInClass();
  }, [classId]);

  return (
    <div className='main-content'>
      <StyledContainer>
        <Paper sx={{ overflow: 'hidden', backgroundColor: '#333' }}>
          {/* Add a title to the page calls Instructors in {class code} */}
          <h1>Instructors in {classCode}</h1>
          <DataGrid
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnMenu // hide the kebab menu on each column
            columnVisibilityModel={{
              // Hide id column
              id: false
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 }
              }
            }}
            pageSizeOptions={[5, 10]}
            sx={{ backgroundColor: '#333' }} // Add this line to change the background color
          />
        </Paper>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity='error'
            sx={{ width: '100%' }}
          >
            {errMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openMessage}
          autoHideDuration={6000}
          onClose={handleCloseMessage}
        >
          <Alert
            onClose={handleCloseMessage}
            severity='success'
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </StyledContainer>
    </div>
  );
}
