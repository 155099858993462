import React, { useState, useEffect } from 'react';
import {
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { UUID } from 'crypto';
import { getScoreBoard } from '../../../../clients/rotr-client';

interface ScoreBoardProps {
  stepId: UUID | undefined;
}

const ScoreBoard: React.FC<ScoreBoardProps> = ({ stepId }) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | null>(null);

  const formatSessionTime = (seconds: number) => {
    const roundedSeconds = Math.round(seconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const getMedalIcon = (index: number) => {
    switch (index) {
      case 0:
        return (
          <EmojiEventsIcon sx={{ verticalAlign: 'middle', color: 'gold' }} />
        );
      case 1:
        return (
          <EmojiEventsIcon sx={{ verticalAlign: 'middle', color: 'silver' }} />
        );
      case 2:
        return (
          <EmojiEventsIcon sx={{ verticalAlign: 'middle', color: '#cd7f32' }} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getScoreBoard(stepId)
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch score board data:', error);
        setError('Failed to fetch score board data. Please try again later.');
        setLoading(false);
      });
  }, [stepId]);

  return (
    <>
      <Typography variant='h4' style={{ margin: '20px 0' }}>
        🌟 Top Ten 🌟
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: '#161A1Dff',
            width: '100%',
            minWidth: '200px',
            maxWidth: '600px'
          }}
        >
          <Table aria-label='top scores table'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='center'>Score</TableCell>
                <TableCell align='center'>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row: any, index: any) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 }
                  }}
                >
                  <TableCell component='th' scope='row'>
                    {row.name} {index < 3 ? getMedalIcon(index) : null}
                  </TableCell>
                  <TableCell align='center'>{row.score}</TableCell>
                  <TableCell align='center'>
                    {formatSessionTime(row.time_seconds)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ScoreBoard;
