import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { signInWithRedirect } from 'aws-amplify/auth';

// Define the styled button
const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: '1rem',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}));

// Create a reusable SignInButton component
const SignInButton: React.FC<ButtonProps> = (props) => {
  const handleSignIn = async () => {
    try {
      await signInWithRedirect();
    } catch (error) {
      console.error('An error occurred whilst Signing In:', error);
    }
  };

  return (
    <StyledButton {...props} onClick={handleSignIn}>
      Sign In
    </StyledButton>
  );
};

export default SignInButton;
