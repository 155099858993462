import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Own
import { Rule, SubRule, RulesBySection } from '../../display-rule/ruleTypes';
import '../../../App.css';
import { rules } from '../../display-rule/rules';
import RuleInput from './ruleInput';
// MUI
import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const LearningRulesPage: React.FC = () => {
  // Params
  const { ruleId, subRuleId } = useParams<{
    ruleId: string;
    subRuleId?: string;
  }>();

  // Selected items
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);
  const [selectedSubRule, setSelectedSubRule] = useState<SubRule | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number | null>(null);

  // UI states
  const [expandedAccordion, setExpandedAccordion] = useState<number | false>(0);

  // Other states
  const [difficulty, setDifficulty] = useState('30');
  const [difficultyLabel, setDifficultyLabel] = useState('Easy');
  const parts = Array.from(new Set(rules.map((rule) => rule.part)));

  const showTextIDs = [
    '3',
    '6-a',
    '6-b',
    '7-d',
    '10-b',
    '12-a',
    '18',
    '18-a',
    '18-b',
    '18-c',
    '22',
    '22-a',
    '22-b',
    '22-c',
    '22-d',
    '23-a',
    '24-a',
    '24-c',
    '24-e',
    '24-f',
    '24-g',
    '25-a',
    '26-b',
    '26-c',
    '27-a',
    '27-b',
    '27-d',
    '27-e',
    '29-a',
    '30-a',
    '30-d',
    '34-a',
    '34-b',
    '34-c',
    '35',
    'a-4-1',
    'a-4-3'
  ];
  const showAllSubRuleIDs = ['a-4-1', 'a-4-3'];

  const difficultyOptions = [
    { label: 'Easy', value: '30' },
    { label: 'Medium', value: '70' },
    { label: 'Hard', value: '100' },
    { label: 'No Prompt', value: '101' }
  ];

  const checkShowText = (id: string) => {
    if (showTextIDs.includes(id)) {
      return true;
    } else return false;
  };
  const checkShowAllSubRules = (id: string) => {
    if (showAllSubRuleIDs.includes(id)) {
      return true;
    } else return false;
  };

  const handleRuleChange = (event: SelectChangeEvent<string>) => {
    // Update Selected Rule
    const ruleId = event.target.value;
    const rule = rules.find((r) => r.id === ruleId);
    setSelectedRule(rule || null);
    setSelectedSubRule(null);
  };

  const handleSubRuleChange = (event: SelectChangeEvent<string>) => {
    // Update Selected Sub Rule
    const subRuleId = event.target.value;
    const subRule = selectedRule?.subRules?.find((sr) => sr.id === subRuleId);
    setSelectedSubRule(subRule || null);
  };

  const selectRule = (ruleId: string) => {
    const rule = rules.find((r) => r.id === ruleId);
    if (rule) {
      setSelectedRule(rule);
      const partIndex = parts.findIndex((part) => part === rule.part);
      setSelectedTabIndex(partIndex);
      if (rule.subRules && rule.subRules.length > 0) {
        setSelectedSubRule(rule.subRules[0]);
      } else {
        setSelectedSubRule(null);
      }
    }
  };
  const handleDifficultyChange = (
    event: React.MouseEvent<HTMLElement>,
    newDifficulty: string
  ) => {
    if (newDifficulty !== null) {
      setDifficulty(newDifficulty);
    }
  };

  const handleAccordionChange =
    (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const handleBack = () => {
    // Reset
    setSelectedTabIndex(null);
    setSelectedRule(null);
  };

  // Function to group rules by section
  const groupRulesBySection = (rules: Rule[], part: string): RulesBySection => {
    return rules
      .filter((rule) => rule.part === part)
      .reduce<RulesBySection>((acc, rule) => {
        const sectionKey = rule.section || 'NoSection'; // A key for rules without a section
        acc[sectionKey] = acc[sectionKey] || [];
        acc[sectionKey].push(rule);
        return acc;
      }, {});
  };

  useEffect(() => {
    if (ruleId) {
      const foundRule = rules.find((r) => r.id === ruleId);
      if (foundRule) {
        setSelectedRule(foundRule);
        const partIndex = parts.findIndex((part) => part === foundRule.part);
        setSelectedTabIndex(partIndex);

        if (subRuleId && foundRule.subRules) {
          const foundSubRule = foundRule.subRules.find(
            (sr) => sr.id === `${ruleId}-${subRuleId}`
          );
          setSelectedSubRule(foundSubRule || null);
        }
      }
    }
  }, []);
  useEffect(() => {
    // Set difficulty label
    setDifficultyLabel(
      difficultyOptions.find((option) => option.value === difficulty)?.label ||
        'Easy'
    );
  }, [difficulty]);

  return (
    <div className='main-content'>
      <Container maxWidth='sm'>
        <Box sx={{ mt: 2, mb: 2 }}>
          {selectedRule === null ? (
            <>
              <Typography
                variant='h5'
                gutterBottom
                align='center'
                sx={{ mt: 2, mb: 4 }}
              >
                Select a Rule and Start Practicing
              </Typography>
              {parts.map((part, partIndex) => (
                <Accordion
                  key={partIndex}
                  className='customAccordion'
                  expanded={expandedAccordion === partIndex}
                  onChange={handleAccordionChange(partIndex)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='customIcon' />}
                    aria-controls={`panel${partIndex}a-content`}
                    id={`panel${partIndex}a-header`}
                    className='customAccordionSummary'
                  >
                    <Typography variant='h6' sx={{ color: 'lightgrey' }}>
                      {part}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className='customAccordionDetails'>
                    {Object.entries(groupRulesBySection(rules, part)).map(
                      ([section, rulesInSection], sectionIndex) => (
                        <div key={sectionIndex}>
                          {section !== 'NoSection' && (
                            <Typography
                              className='ruleSection'
                              variant='subtitle1'
                              sx={{ mt: 2 }}
                            >
                              {section}
                            </Typography>
                          )}
                          {rulesInSection.map((rule, i) => (
                            <Box
                              key={i}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                              }}
                              onClick={() => selectRule(rule.id)}
                              className='ruleItem'
                            >
                              {' '}
                              <DoubleArrowIcon color='primary' />
                              <Typography
                                key={rule.id}
                                variant='body1'
                                sx={{ ml: 1, cursor: 'pointer' }}
                                align='left'
                              >
                                {rule.title}
                              </Typography>{' '}
                            </Box>
                          ))}
                        </div>
                      )
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          ) : (
            <>
              {/* Check if selectedTabIndex is not null before using it as an index */}
              {selectedTabIndex !== null && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start', // Aligns content to the left
                      marginBottom: 1 // Space between this box and the next
                    }}
                  >
                    <Tooltip title='Return to part selection'>
                      <Button
                        startIcon={<ArrowBackIcon />}
                        onClick={handleBack}
                        variant='outlined'
                      >
                        select Part
                      </Button>
                    </Tooltip>
                  </Box>

                  <Box mt={4}>
                    <ToggleButtonGroup
                      sx={{ color: 'white', borderColor: 'white' }}
                      value={difficulty}
                      exclusive
                      onChange={handleDifficultyChange}
                      aria-label='Platform'
                    >
                      {difficultyOptions.map((option) => (
                        <ToggleButton
                          className='customToggleButton'
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel
                        id='rule-select-label'
                        className='customInputLabel'
                      >
                        Select Rule
                      </InputLabel>
                      <Select
                        labelId='rule-select-label'
                        value={selectedRule ? selectedRule.id : ''}
                        onChange={handleRuleChange}
                        label='Select Rule'
                        className='myCustomSelect'
                        MenuProps={{
                          PaperProps: {
                            className: 'myCustomSelectMenu'
                          }
                        }}
                      >
                        {rules
                          .filter(
                            (rule) => rule.part === parts[selectedTabIndex]
                          )
                          .filter(
                            (rule) =>
                              !selectedRule.section ||
                              rule.section === selectedRule.section
                          )
                          .map((rule) => (
                            <MenuItem key={rule.id} value={rule.id}>
                              {rule.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  {selectedRule && (
                    <Box sx={{ mt: 2 }}>
                      {!selectedRule.subRules && (
                        <RuleInput
                          ruleId={selectedRule.id}
                          ruleString={selectedRule.description}
                          part={selectedRule.part}
                          section={selectedRule.section || undefined}
                          fullTitle={selectedRule.title}
                          ruleTitle={selectedRule.title}
                          difficulty={difficulty}
                          difficultyLabel={difficultyLabel}
                          showTitle={false}
                        />
                      )}
                      {selectedRule.subRules &&
                        checkShowText(selectedRule.id) && (
                          <Typography
                            align='left'
                            sx={{ mt: 3, mb: 2, ml: 2 }}
                            variant='body1'
                            gutterBottom
                          >
                            <span className='large-quote'>&ldquo;</span>
                            {selectedRule.description}
                            <span className='large-quote'>&rdquo;</span>
                          </Typography>
                        )}
                      {selectedRule.subRules &&
                        !checkShowAllSubRules(selectedRule.id) && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: 2
                            }}
                          >
                            <FormControl
                              fullWidth
                              variant='outlined'
                              sx={{ mt: 2 }}
                            >
                              <InputLabel
                                id='sub-rule-select-label'
                                className='customInputLabel'
                              >
                                Select paragraph
                              </InputLabel>
                              <Select
                                labelId='sub-rule-select-label'
                                className='myCustomSelect'
                                value={
                                  selectedSubRule ? selectedSubRule.id : ''
                                }
                                onChange={handleSubRuleChange}
                                label='Select paragraph'
                                MenuProps={{
                                  PaperProps: {
                                    className: 'myCustomSelectMenu'
                                  }
                                }}
                              >
                                {selectedRule.subRules.map((subRule) => (
                                  <MenuItem key={subRule.id} value={subRule.id}>
                                    {subRule.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                      {/* Display Multiple Sub Rules  */}
                      {selectedRule.subRules &&
                        checkShowAllSubRules(selectedRule.id) && (
                          <Box sx={{ mt: 2 }}>
                            {selectedRule.subRules.map((subRule, i) => (
                              <RuleInput
                                key={subRule.id}
                                ruleId={subRule.id}
                                ruleString={subRule.description}
                                part={selectedRule.part}
                                section={selectedRule.section || undefined}
                                fullTitle={
                                  selectedRule.title + ' ' + subRule.title
                                }
                                ruleTitle={subRule.title}
                                difficulty={difficulty}
                                difficultyLabel={difficultyLabel}
                                showTitle={true}
                              />
                            ))}
                          </Box>
                        )}

                      {selectedSubRule &&
                        !checkShowAllSubRules(selectedRule.id) && (
                          <Box
                            sx={{
                              mt: 2,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {/* Display Single Sub Rule and no sub sub rules*/}
                            {!selectedSubRule.subSubRules && (
                              <RuleInput
                                ruleId={selectedSubRule.id}
                                ruleString={selectedSubRule.description}
                                part={selectedRule.part}
                                section={selectedRule.section || undefined}
                                fullTitle={
                                  selectedRule.title +
                                  ' ' +
                                  selectedSubRule.title
                                }
                                ruleTitle={selectedSubRule.title}
                                difficulty={difficulty}
                                difficultyLabel={difficultyLabel}
                                showTitle={false}
                              />
                            )}
                            {/* Display all sub sub rules*/}
                            {selectedSubRule.subSubRules && (
                              <Box sx={{ width: '100%', mt: 2 }}>
                                {checkShowText(selectedSubRule.id) && (
                                  <Typography
                                    align='left'
                                    sx={{ mt: 3, mb: 2, ml: 2 }}
                                    variant='body1'
                                    gutterBottom
                                  >
                                    <span className='large-quote'>&ldquo;</span>
                                    {selectedSubRule.description}
                                    <span className='large-quote'>&rdquo;</span>
                                  </Typography>
                                )}
                                {selectedSubRule.subSubRules.map(
                                  (subSubRule, i) => (
                                    <RuleInput
                                      key={subSubRule.id}
                                      ruleId={subSubRule.id}
                                      ruleString={subSubRule.description}
                                      part={selectedRule.part}
                                      section={
                                        selectedRule.section || undefined
                                      }
                                      fullTitle={
                                        selectedRule.title +
                                        ' ' +
                                        selectedSubRule.title +
                                        ' ' +
                                        subSubRule.title
                                      }
                                      ruleTitle={subSubRule.title}
                                      difficulty={difficulty}
                                      difficultyLabel={difficultyLabel}
                                      showTitle={true}
                                    />
                                  )
                                )}
                              </Box>
                            )}
                          </Box>
                        )}
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default LearningRulesPage;
