import React from 'react';
import {
  Typography,
  Box,
  IconButton,
  Modal,
  List,
  ListItem
} from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

import { Rule } from './ruleTypes';

interface ShowRuleProps {
  ruleId: string | undefined;
  subRuleId: string | undefined;
  subSubRuleId: string | undefined;
  open: boolean;
  onClose: () => void;
  rules: Rule[];
}

const RuleModal: React.FC<ShowRuleProps> = ({
  ruleId,
  subRuleId,
  subSubRuleId,
  open,
  onClose,
  rules
}) => {
  const rule = ruleId ? rules.find((r) => r.id === ruleId) : undefined;

  const renderContent = () => {
    if (!rule) return null;

    if (subSubRuleId) {
      const fullSubRuleId = `${ruleId}-${subRuleId}`;
      const fullSubSubRuleId = `${fullSubRuleId}-${subSubRuleId}`;
      const subRule = rule.subRules?.find((sr) => sr.id === fullSubRuleId);
      const subSubRule = subRule?.subSubRules?.find(
        (ssr) => ssr.id === fullSubSubRuleId
      );
      if (subSubRule) {
        return (
          <>
            <Typography variant='h6' gutterBottom sx={{ color: 'black' }}>
              {rule.title} {subRule?.title} {subSubRule.title}
            </Typography>
            <Typography variant='body1' sx={{ color: 'black' }}>
              {subSubRule.description}
            </Typography>
          </>
        );
      }
    }

    if (subRuleId) {
      const fullSubRuleId = `${ruleId}-${subRuleId}`;
      const subRule = rule.subRules?.find((sr) => sr.id === fullSubRuleId);
      if (subRule) {
        return (
          <>
            <Typography variant='h6' gutterBottom sx={{ color: 'black' }}>
              {rule.title} {subRule.title}
            </Typography>
            <Typography variant='body1' sx={{ color: 'black' }}>
              {subRule.description}
            </Typography>
            {subRule.subSubRules && (
              <List>
                {subRule.subSubRules.map((ssr) => (
                  <ListItem key={ssr.id}>
                    <Typography variant='body2' sx={{ color: 'black' }}>
                      <strong>{ssr.title}</strong> {ssr.description}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        );
      }
    }

    return (
      <>
        <Typography variant='h6' gutterBottom sx={{ color: 'black' }}>
          {rule.title}
        </Typography>
        <Typography variant='body1' sx={{ color: 'black' }}>
          {rule.description}
        </Typography>
        {rule.subRules && (
          <List>
            {rule.subRules.map((sr) => (
              <ListItem key={sr.id}>
                <Box>
                  <Typography variant='body1' sx={{ color: 'black' }}>
                    <strong>{sr.title}:</strong> {sr.description}
                  </Typography>
                  {sr.subSubRules && (
                    <List>
                      {sr.subSubRules.map((ssr) => (
                        <ListItem key={ssr.id}>
                          <Typography variant='body2' sx={{ color: 'black' }}>
                            <strong>{ssr.title}</strong> {ssr.description}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        )}
      </>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '4px',
          p: 6
        }}
      >
        <IconButton
          size='large'
          sx={{
            color: 'black',
            position: 'absolute',
            top: -8,
            left: -8
          }}
          onClick={onClose}
        >
          <CancelTwoToneIcon fontSize='large' />
        </IconButton>
        <Box>
          {rule && (
            <Typography
              sx={{ color: 'black' }}
              gutterBottom
              variant='subtitle2'
            >
              {rule.part}
              {rule.section ? ' > ' + rule.section : ''}
            </Typography>
          )}
          {renderContent()}
        </Box>
      </Box>
    </Modal>
  );
};

export default RuleModal;
