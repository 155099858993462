import React, { useState } from 'react';
import { Snackbar, Alert, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SignInButton from '../common/SignInButton';

const SignUpImage = styled('img')({
  maxWidth: '100%',
  '@media (max-width:600px)': {
    display: 'none'
  }
});

const LargeTypography = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '1rem',
  '@media (max-width:600px)': {
    textAlign: 'center'
  }
});

const NotSignedInContent = () => {
  const [openError, setOpenError] = useState(false);
  const handleCloseError = () => setOpenError(false);
  const [errMsg, setErrMsg] = useState('');

  return (
    <Box
      className='main-content'
      display='flex'
      flexDirection='column'
      alignItems='center'
      p={3}
    >
      <Box
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems='center'
        justifyContent='center'
        mb={3}
      >
        <Box
          flex={1}
          textAlign={{ xs: 'center', sm: 'left' }}
          mb={{ xs: 2, sm: 0 }}
        >
          <LargeTypography variant='h2'>
            ROTR training just got smarter
          </LargeTypography>
          <Typography variant='h6' gutterBottom>
            Learn through interactive ROTR scenarios, practice reporting and
            applying the rules.
          </Typography>
        </Box>
        <Box flex={1} display='flex' justifyContent='center'>
          <SignUpImage src='smart_trainer.png' alt='Smart ROTR Training' />
        </Box>
      </Box>
      <SignInButton variant='contained' size='large' />
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotSignedInContent;
