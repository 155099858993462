// home page

import React, { useEffect, useState } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Container,
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Alert,
  Grid,
  Paper,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Snackbar,
  List,
  Skeleton
} from '@mui/material';
import { styled, alpha, keyframes } from '@mui/material/styles';
import NotSignedInContent from './not-signed';
import { ListUserClasses, ListUserTasks } from '../../clients/rotr-client';
import RadarIcon from '@mui/icons-material/Radar';
import ListIcon from '@mui/icons-material/List';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import * as amplitude from '@amplitude/analytics-browser';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import QuizIcon from '@mui/icons-material/Quiz';
import CardActions from '@mui/material/CardActions';

const StyledButton = styled(Button)({
  fontWeight: 'bold'
});
const StyledContainer = styled(Container)({
  marginTop: '5rem',
  textAlign: 'center'
});

const AnimatedIcon = styled('i')({
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.2)'
  }
});

const LargeTypography = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '1rem'
});
const UserGuideCard = styled(Card)({
  marginTop: '2rem',
  padding: '2rem',
  backgroundColor: '#161A1Dff',
  color: 'whitesmoke'
});

const StepContainer = styled('div')({
  padding: '2rem 0'
});
const StyledStepLabel = styled(StepLabel)({
  '& .MuiStepLabel-iconContainer': {
    transform: 'scale(1.8)'
  },
  '&:hover': {
    backgroundColor: '#5243AAff'
  }
});

const GradientPaper = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.primary.main,
    0.001
  )} 0%, ${alpha(theme.palette.secondary.main, 0.001)} 100%)`,
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`
}));

const AnimatedListItem = styled(ListItemButton)(({ theme }) => ({
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateX(10px)',
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  }
}));

interface ClassTaskData {
  taskID: string;
  taskType: string;
  taskName: string;
  taskPracticeLink: string;
  completed: boolean;
}

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
`;

const TaskItem = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
  transition: 'all 0.3s ease',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center'
  }
}));

const TaskContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
    marginRight: theme.spacing(2)
  }
}));

const TaskActions = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  }
}));

const IncompleteTaskItem = styled(TaskItem)(({ theme }) => ({
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.primary.main,
    0.1
  )} 0%, ${alpha(theme.palette.secondary.main, 0.1)} 100%)`,
  boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.15)}`,
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: `0 6px 25px ${alpha(theme.palette.primary.main, 0.25)}`
  }
}));

const CompletedTaskItem = styled(TaskItem)(({ theme }) => ({
  background: alpha(theme.palette.grey[800], 0.2),
  opacity: 0.7
}));

const RetryButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: '8px 20px',
  transition: 'all 0.3s ease',
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  background: 'transparent',
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    transform: 'scale(1.05)'
  }
}));

const StyledStartButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: '8px 20px',
  transition: 'all 0.3s ease',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  boxShadow: `0 4px 10px ${alpha(theme.palette.primary.main, 0.3)}`,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  },
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 6px 15px ${alpha(theme.palette.primary.main, 0.4)}`
  }
}));

const ActionHeader = styled(Typography)(({ theme }) => ({
  fontSize: '1.75rem',
  fontWeight: 'bold',
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(3),
  textAlign: 'center'
}));

const TaskExplanation = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3),
  textAlign: 'center'
}));

const TaskList = styled(List)(({ theme }) => ({
  padding: 0
}));

const PracticeCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: `linear-gradient(145deg, ${alpha(
    theme.palette.primary.main,
    0.05
  )} 0%, ${alpha(theme.palette.secondary.main, 0.05)} 100%)`,
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: `0 5px 15px ${alpha(theme.palette.common.white, 0.1)}`
  }
}));

const PracticeIcon = styled('div')(({ theme }) => ({
  fontSize: '3rem',
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main
}));

const PracticeCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center'
}));

const PracticeCardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1)
}));

const PracticeCardDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

interface HomeProps {
  isAuthenticated: boolean;
  currentUser: any;
  userEmail: string | null;
  isLoading: boolean;
}

const Home: React.FC<HomeProps> = ({
  isAuthenticated,
  currentUser,
  userEmail,
  isLoading
}) => {
  const steps = [
    'Create a scenario in "Practice".',
    'Analyse contacts using "Radar" and "Lookout" tabs.',
    'Report to the Captain via the "Report" form.',
    'Review feedback to enhance your next report.'
  ];
  const GuideContainer = styled('div')({
    maxWidth: '80%', // Adjust as needed
    margin: '0 auto',
    textAlign: 'left'
  });

  const [jsonData, setJsonData] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [announcement, setAnnouncement] = useState(false);
  const [currentClass, setCurrentClass] = useState<string | undefined>(
    undefined
  );
  const [userTasks, setUserTasks] = useState<ClassTaskData[]>([]);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const theme = useTheme();
  const handleCloseError = () => setOpenError(false);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);

  const GuideSection = () => {
    return (
      <UserGuideCard>
        <Typography
          variant='h5'
          align='center'
          gutterBottom
          fontWeight={'bold'}
          style={{ color: 'whitesmoke' }}
        >
          How does it work?
        </Typography>
        <GuideContainer>
          <Stepper orientation='vertical'>
            {steps.map((step, index) => (
              <Step key={index} active={true} style={{ padding: '1rem 0' }}>
                <StyledStepLabel>
                  <Typography
                    variant='body1'
                    color={'whitesmoke'}
                    marginLeft={'1rem'}
                  >
                    {step}
                  </Typography>
                </StyledStepLabel>
              </Step>
            ))}
          </Stepper>
        </GuideContainer>
      </UserGuideCard>
    );
  };

  const navigate = useNavigate();

  const navigateToPractice = () => {
    navigate('/practice');
  };

  const navigateToProfile = () => {
    navigate('/profile');
  };
  const navigateToPracticeRecite = () => {
    navigate('/practice/recite');
  };
  const navigateToPracticeQuizzes = () => {
    navigate('/games/shake-down');
  };

  const showNotSignedIn = () => {
    return (
      <div>
        {isLoading && <CircularProgress />}
        {!isAuthenticated && !isLoading && <NotSignedInContent />}
      </div>
    );
  };

  const getUserClasses = async () => {
    setIsLoadingTasks(true);
    try {
      const classes = await ListUserClasses();
      if (
        classes.some(
          (c: { is_current_class: boolean }) => c.is_current_class === true
        )
      ) {
        setOpen(false);
        setCurrentClass(
          classes.find(
            (c: { is_current_class: boolean }) => c.is_current_class === true
          ).code
        );
      } else {
        setCurrentClass(undefined);
        setOpen(true);
      }
    } catch (error: any) {
      // console.error(error);
    } finally {
      setIsLoadingTasks(false);
    }
  };

  const getUserTasks = async () => {
    setIsLoadingTasks(true);
    try {
      const tasks = await ListUserTasks();

      // Assuming tasks array has a 'completed' property
      const classTasks: ClassTaskData[] = tasks.map((t: any) => ({
        taskID: t.id,
        taskType: t.task_type,
        taskName: t.task_title,
        taskPracticeLink:
          (t.task_type === 'recite'
            ? '/practice/recite/'
            : t.task_type === 'quiz'
            ? '/games/shake-down/steps/'
            : '/practice/') + t.task_url,
        completed: !!t.completed_at
      }));
      // Sort tasks: Incomplete first, then complete. Limit to 5 items
      const sortedTasks = classTasks
        .sort((a, b) => Number(a.completed) - Number(b.completed))
        .slice(0, 5);

      setUserTasks(sortedTasks);
    } catch (error) {
      // console.error(error);
    } finally {
      setIsLoadingTasks(false);
    }
  };

  const getTaskIcon = (taskType: string) => {
    switch (taskType) {
      case 'recite':
        return <RecordVoiceOverIcon fontSize='large' />;
      case 'scenario':
        return <RadarIcon fontSize='large' />;
      case 'quiz':
        return <QuizIcon fontSize='large' />;
      default:
        return <RadarIcon fontSize='large' />;
    }
  };

  const handleTaskClick = (task: any) => {
    switch (task.taskType) {
      case 'recite':
        amplitude.logEvent('Started Recite Task');
        break;
      case 'scenario':
        amplitude.logEvent('Generated Task Scenario');
        break;
      default:
    }

    navigate(task.taskPracticeLink);
  };

  const handleRetry = (task: any) => {
    amplitude.logEvent('Retried Completed Task', { taskType: task.taskType });
    navigate(task.taskPracticeLink);
  };

  useEffect(() => {
    console.log('isAuthenticated', isAuthenticated);
    if (isAuthenticated) {
      getUserClasses();
      getUserTasks();
    }
  }, [isAuthenticated]);

  const practiceTypes = [
    {
      title: 'Scenarios',
      icon: <RadarIcon fontSize='large' />,
      description: 'Practice realistic collision avoidance scenarios.',
      action: navigateToPractice
    },
    {
      title: 'Recite',
      icon: <RecordVoiceOverIcon fontSize='large' />,
      description:
        'Improve your knowledge of collision regulations through recitation.',
      action: navigateToPracticeRecite
    },
    {
      title: 'Quizzes',
      icon: <QuizIcon fontSize='large' />,
      description: 'Test your understanding with interactive quizzes.',
      action: navigateToPracticeQuizzes,
      disabled: true
    }
  ];

  // log the user email

  return (
    <ThemeProvider theme={theme}>
      <div
        className='main-content'
        style={{
          color: 'whitesmoke',
          minHeight: '100vh',
          paddingBottom: '2rem'
        }}
      >
        <Box maxWidth='md' mx='auto'>
          {!isAuthenticated ? (
            showNotSignedIn()
          ) : (
            <>
              <GradientPaper
                elevation={3}
                sx={{ p: 4, mt: 3, borderRadius: '16px' }}
              >
                <ActionHeader variant='h4'>Daily Learning Tasks</ActionHeader>
                <TaskExplanation>
                  Tackle these assignments to enhance your skills and track your
                  progress:
                </TaskExplanation>
                {isLoadingTasks ? (
                  // Skeleton loading state
                  [...Array(3)].map((_, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Skeleton
                        variant='rectangular'
                        width='100%'
                        height={60}
                        sx={{ borderRadius: '8px' }}
                      />
                    </Box>
                  ))
                ) : userTasks.length > 0 ? (
                  <TaskList>
                    {userTasks.map((task: ClassTaskData) =>
                      task.completed ? (
                        <CompletedTaskItem key={task.taskID}>
                          <TaskContent>
                            <ListItemIcon sx={{ color: 'grey', minWidth: 56 }}>
                              {getTaskIcon(task.taskType)}
                            </ListItemIcon>
                            <ListItemText
                              primary={task.taskName}
                              primaryTypographyProps={{
                                fontWeight: 'medium',
                                style: { textDecoration: 'line-through' }
                              }}
                              sx={{ textAlign: 'left', ml: 2 }}
                            />
                          </TaskContent>
                          <TaskActions>
                            <TaskAltTwoToneIcon color='success' />
                            <RetryButton
                              variant='outlined'
                              size='small'
                              onClick={() => handleRetry(task)}
                            >
                              Retry
                            </RetryButton>
                          </TaskActions>
                        </CompletedTaskItem>
                      ) : (
                        <IncompleteTaskItem key={task.taskID}>
                          <TaskContent>
                            <ListItemIcon
                              sx={{ color: 'primary.main', minWidth: 56 }}
                            >
                              {getTaskIcon(task.taskType)}
                            </ListItemIcon>
                            <ListItemText
                              primary={task.taskName}
                              primaryTypographyProps={{
                                fontWeight: 'medium'
                              }}
                              sx={{ textAlign: 'left', ml: 2 }}
                            />
                          </TaskContent>
                          <TaskActions>
                            <StyledStartButton
                              variant='contained'
                              onClick={() => handleTaskClick(task)}
                            >
                              Start
                            </StyledStartButton>
                          </TaskActions>
                        </IncompleteTaskItem>
                      )
                    )}
                  </TaskList>
                ) : (
                  <Alert severity='info' sx={{ mt: 3, borderRadius: '8px' }}>
                    You have no allocated tasks at this time.
                  </Alert>
                )}
              </GradientPaper>

              <GradientPaper
                elevation={3}
                sx={{ p: 4, mt: 3, borderRadius: '16px' }}
              >
                <ActionHeader variant='h4'>Free Play</ActionHeader>
                <TaskExplanation>
                  Decide what you want to practice, select a method below and
                  practice as much as you want:
                </TaskExplanation>
                <Grid container spacing={3}>
                  {practiceTypes.map((type, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <PracticeCard>
                        <PracticeCardContent>
                          <PracticeIcon>{type.icon}</PracticeIcon>
                          <PracticeCardTitle variant='h6'>
                            {type.title}
                          </PracticeCardTitle>
                          <PracticeCardDescription variant='body2'>
                            {type.description}
                          </PracticeCardDescription>
                        </PracticeCardContent>
                        <CardActions>
                          <StyledButton
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={type.action}
                            disabled={type.disabled}
                          >
                            Try Now
                          </StyledButton>
                        </CardActions>
                      </PracticeCard>
                    </Grid>
                  ))}
                </Grid>
              </GradientPaper>
            </>
          )}
        </Box>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity='error'
            sx={{ width: '100%' }}
          >
            {errMsg}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
};

export default Home;
