import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Alert, Skeleton } from '@mui/material';
import NextQuestion from './NextQuestion';
import { postAnswer } from '../../../../clients/rotr-client';
import { UUID } from 'crypto';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CloseIcon from '@mui/icons-material/Close'; // Add this import
import { max } from 'lodash';
import RuleDisplay from './RuleDisplay';

interface Option {
  optionId: string;
  optionContent: string;
}

interface Question {
  attemptID: UUID;
  content: string;
  instruction: string;
  mediaURL: string;
  options: Option[];
  questionID: string;
  questionNumber: number;
  rules: string[];
  token: string;
  totalQuestions: number;
  type: string;
  selectLimit: number;
}

interface RenderMultipleChoiceQuestionProps {
  attemptId: UUID;
  questionId: UUID;
  currentQuestion: Question;
  currentQuestionIndex: number;
  submitted: boolean;
  setSubmitted: (value: boolean) => void;
  handleNextQuestion: () => void;
  updateScore: (value: number) => void;
  setTimerRunning: (value: boolean) => void;
}

const RenderMultipleChoiceQuestion: React.FC<
  RenderMultipleChoiceQuestionProps
> = ({
  attemptId,
  questionId,
  currentQuestion,
  currentQuestionIndex,
  submitted,
  setSubmitted,
  handleNextQuestion,
  updateScore,
  setTimerRunning
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [correctOptions, setCorrectOptions] = useState<string[]>([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [correctOptionsLoaded, setCorrectOptionsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [showRuleLink, setShowRuleLink] = useState(false);
  const [rules, setRules] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    setShowRuleLink(false);
    setShowLimitAlert(false);
    setTimerRunning(true);
    setShowNextButton(false);
    setSelectedOptions([]);
    setCorrectOptions([]);
    setImageLoading(true);

    // Ensure rules is always an array of strings
    const newRules = Array.isArray(currentQuestion.rules)
      ? currentQuestion.rules
          .flat()
          .filter((rule): rule is string => typeof rule === 'string')
      : [];
    setRules(newRules);
  }, [currentQuestion]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedOptions, attemptId, questionId]);

  useEffect(() => {
    const tempIsCorrect =
      correctOptions.length > 0 &&
      correctOptions.every((optionId) => selectedOptions.includes(optionId)) &&
      selectedOptions.length === correctOptions.length;
    setIsCorrect(tempIsCorrect);
    setShowRuleLink(!tempIsCorrect && submitted);
  }, [correctOptions]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showLimitAlert) {
      timer = setTimeout(() => {
        setShowLimitAlert(false);
      }, 5000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showLimitAlert]);

  const handleOptionSelect = (optionId: string) => {
    if (selectedOptions.includes(optionId)) {
      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
      setShowLimitAlert(false);
    } else {
      if (selectedOptions.length < currentQuestion.selectLimit) {
        setSelectedOptions([...selectedOptions, optionId]);
        setShowLimitAlert(false);
      } else {
        setShowLimitAlert(true);
      }
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    setTimerRunning(false);
    const answers = currentQuestion.options.map((option) => {
      return {
        id: option.optionId,
        answer: selectedOptions.includes(option.optionId)
      };
    });
    try {
      const APIResponse = await postAnswer(attemptId, questionId, answers);
      APIResponse.responseAnswers.forEach((option: any) => {
        if (option.is_correct) {
          setCorrectOptions((prevCorrectOptions) => [
            ...prevCorrectOptions,
            option.id
          ]);
        }
      });
      setCorrectOptionsLoaded(true);
      updateScore(APIResponse.score);
      setShowNextButton(true);
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };

  const RenderMultipleChoiceOptions = () => {
    if (!currentQuestion.options || currentQuestion.options.length === 0) {
      return (
        <Typography variant='h6' sx={{ color: 'red' }}>
          No options available for this question.
        </Typography>
      );
    }
    return (
      <Box
        sx={{
          width: '100%',
          marginTop: currentQuestion.mediaURL ? '10px' : '50px',
          textAlign: 'center',
          color: 'white'
        }}
      >
        <div>
          {showLimitAlert && (
            <Alert
              severity='warning'
              style={{ marginBottom: '20px' }}
              action={
                <Button
                  color='inherit'
                  size='small'
                  onClick={() => setShowLimitAlert(false)}
                >
                  <CloseIcon />
                </Button>
              }
            >
              You can only select {currentQuestion.selectLimit} options.
            </Alert>
          )}
          {error && <Alert severity='error'>{error}</Alert>}
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {currentQuestion.options.map((option) => (
            <Button
              key={option.optionId}
              variant='contained'
              onClick={() => handleOptionSelect(option.optionId)}
              sx={{
                backgroundColor: getButtonColor(option.optionId),
                color: 'white',
                textAlign: 'left',
                justifyContent: 'space-between', // Change this to space-between
                padding: '12px 24px',
                borderRadius: '5px',
                marginBottom: '10px',
                width: '100%',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: getButtonHoverColor(option.optionId)
                },
                display: 'flex'
              }}
            >
              <Typography variant='body1' align='left'>
                {option.optionContent}
              </Typography>
              {selectedOptions.includes(option.optionId) && (
                <CloseIcon
                  sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                    color: 'white'
                  }}
                  onClick={() => handleOptionSelect(option.optionId)}
                />
              )}
            </Button>
          ))}
        </Box>
      </Box>
    );
  };

  const getButtonColor = (optionId: string) => {
    if (submitted && correctOptionsLoaded) {
      if (correctOptions?.includes(optionId)) {
        return 'green';
      } else if (
        correctOptions.length > 0 &&
        selectedOptions.includes(optionId) &&
        !correctOptions?.includes(optionId)
      ) {
        return 'red';
      } else if (
        correctOptions.length > 0 &&
        !selectedOptions.includes(optionId) &&
        !correctOptions?.includes(optionId)
      ) {
        return '#1E1E1E';
      }
    } else {
      if (selectedOptions.includes(optionId)) {
        return {
          border: '2px solid purple',
          backgroundColor: 'transparent'
        };
      }
    }
    return '#1286A6';
  };

  const getButtonHoverColor = (optionId: string) => {
    if (selectedOptions.includes(optionId) && !submitted) {
      return {
        border: '2px solid purple',
        backgroundColor: 'transparent'
      };
    }
    return '#1286A6';
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: '4px 0',
        padding: '0 12px',
        textAlign: 'left',
        color: 'white',
        position: 'relative',
        zIndex: 2
      }}
    >
      <Box
        style={{
          backgroundColor:
            isCorrect && submitted
              ? 'green'
              : submitted
              ? 'transparent'
              : 'purple',
          padding: '20px',
          borderRadius: '10px',
          marginBottom: '10px'
        }}
        sx={{
          zIndex: 3
        }}
      >
        <Typography variant='h6' sx={{ fontSize: '1.0rem' }}>
          <strong>{currentQuestion.content}</strong>
          <RuleDisplay inputRules={rules} showRuleLink={showRuleLink} />
          {isCorrect && submitted && (
            <Typography
              variant='h4'
              sx={{ color: 'white', marginTop: '10px', fontWeight: 'bold' }}
            >
              Correct! <CheckCircleTwoToneIcon />
            </Typography>
          )}
        </Typography>
      </Box>

      <Box
        sx={{
          position: 'relative',
          marginBottom: '12px',
          minHeight: '50px'
        }}
      >
        {currentQuestion.mediaURL && (
          <Box
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              minHeight: '100px',
              maxHeight: '300px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              zIndex: 1,
              overflow: 'hidden'
            }}
          >
            {imageLoading && (
              <Skeleton
                variant='rectangular'
                width='689px'
                height='689px'
                animation='wave'
                sx={{
                  zIndex: 2,
                  bgcolor: 'grey.980'
                }}
              />
            )}
            <img
              src={`${process.env.REACT_APP_URL}/${currentQuestion.mediaURL}`}
              alt='Question Image'
              style={{
                width: '80%',
                height: '30%',
                objectFit: 'cover',
                objectPosition: 'center',
                display: imageLoading ? 'none' : 'block'
              }}
              onLoad={() => setImageLoading(false)}
            />
          </Box>
        )}

        <Box
          sx={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            zIndex: 2
          }}
        >
          {!submitted ? (
            <Button
              variant='contained'
              sx={{
                backgroundColor: 'purple',
                '&:hover': {
                  backgroundColor: '#800080'
                },
                padding: '10px 20px',
                width: '150px',
                fontSize: '1rem'
              }}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          ) : (
            showNextButton && (
              <Box sx={{ width: '150px' }}>
                <NextQuestion onClick={handleNextQuestion} />
              </Box>
            )
          )}
        </Box>
      </Box>

      {RenderMultipleChoiceOptions()}
    </Box>
  );
};

export default RenderMultipleChoiceQuestion;
