import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@mui/material/styles';

const NotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box
        className='main-content'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        minHeight='60vh'
        textAlign='center'
        px={3}
      >
        <Typography variant='h1' component='h1' gutterBottom>
          404
        </Typography>
        <Typography variant='h4' component='h2' gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant='body1' color='text.secondary' paragraph>
          The page you are looking for doesn't exist or has been moved.
        </Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate('/')}
          sx={{ mt: 2 }}
        >
          Go to Home
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default NotFound;
