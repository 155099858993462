import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import {
  GetUsersByOrgId,
  GetOrganisationByAdmin,
  ResetUserPassword,
  GetClassesByOrgId,
  CreateNewUser,
  NewUserModel,
  ListUserClasses
} from '../../clients/rotr-client';
import { styled } from '@mui/material/styles';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  TextField,
  Tooltip
} from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import FeedbackSummary from '../feedback summaries/feedbackSummary';
import { getCurrentUser } from 'aws-amplify/auth';
import { AuthUser } from '@aws-amplify/auth';
import Profile from '../user/profile';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { LockReset, Visibility, VisibilityOff } from '@mui/icons-material';
import CustomDialog from '../common/CustomDialog';

interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
  classCount: number;
  email: string;
}

interface ClassModel {
  id: number;
  code: string;
  student_count: number;
  teacher_count: number;
}

// define a model for a new user with first name, last name, email, password and class

const StyledContainer = styled(Container)({
  textAlign: 'center',
  '@media (min-width: 600px)': {
    maxWidth: '600px' // Set max width to 60px on large screens
  }
});

export default function Users() {
  const [openFeedbackSummary, setOpenFeedbackSummary] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserFirstname, setSelectedUserFirstname] = useState('');
  const [rows, setRows] = useState<UserModel[]>([]);
  const [classes, setClasses] = useState<ClassModel[]>([]);

  const [organisationId, setOrganisationId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const defaultNewUser: NewUserModel = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    classId: '',
    role: '',
    admin: false
  };
  const [newUser, setNewUser] = useState<NewUserModel>(defaultNewUser);
  const [openNewUserView, setOpenNewUserView] = useState(false);
  const [disableCreateUserButton, setDisableCreateUserButton] = useState(true);

  useEffect(() => {
    if (
      newUser.firstName &&
      newUser.lastName &&
      newUser.email &&
      newUser.password &&
      newUser.classId &&
      newUser.role
    ) {
      setDisableCreateUserButton(false);
    } else {
      setDisableCreateUserButton(true);
    }
  }, [newUser]);

  const handleCreateUser = () => {
    CreateNewUser(organisationId, newUser)
      .then((response) => {
        // insert the new user into the rows using the response username and the new user details
        const newUserRow: UserModel = {
          id: response.data.username,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          classCount: 1,
          email: newUser.email
        };
        setRows([...rows, newUserRow]);
        setSnackbarMsg('User created successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setOpenNewUserView(false);
        setNewUser(defaultNewUser);
      })
      .catch((error) => {
        console.error('Error creating user:', error);
        setSnackbarMsg(error.response?.data);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const handleCloseFeedbackSummary = () => setOpenFeedbackSummary(false);
  const handleClosePreview = async () => {
    setOpenProfile(false);
    const userClasses = await ListUserClasses('all', selectedUserId);
    setRows(
      rows.map((row) => {
        if (row.id === selectedUserId) {
          return { ...row, classCount: userClasses.length };
        }
        return row;
      })
    );
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 250 },
    { field: 'firstName', headerName: 'First name', flex: 1 },
    { field: 'lastName', headerName: 'Last name', minWidth: 30, flex: 1 },
    {
      field: 'classCount',
      headerName: 'Classes',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            setSelectedUserId(params.row.id);
            setSelectedUserFirstname(params.row.firstName);
            setOpenProfile(true);
          }}
        >
          {params.value}
        </Button>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 30,
      flex: 1,
      renderCell(params) {
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title='Copy User ID' arrow>
                <IconButton
                  size='small'
                  sx={{
                    color: 'white'
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(params.row.id);
                    setSnackbarMsg('User ID copied to clipboard');
                    setSnackbarSeverity('success');
                    setOpenSnackbar(true);
                  }}
                >
                  <PermIdentityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Reset User Password' arrow>
                <IconButton
                  size='small'
                  sx={{
                    color: 'white'
                  }}
                  onClick={() => {
                    setSelectedUserId(params.row.id);
                    setOpenPasswordReset(true);
                  }}
                >
                  <LockReset fontSize='small' />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      }
    }
  ];

  const handleResetPassword = () => {
    ResetUserPassword(organisationId, selectedUserId, newPassword)
      .then((response) => {
        navigator.clipboard.writeText(newPassword);
        setNewPassword(''); // clear the password
        setSnackbarMsg(
          'Password reset successfully, new password has been copied to clipboard.'
        );
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error('Error resetting password:', error);
        setSnackbarMsg(error.response?.data);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      })
      .finally(() => {
        setOpenPasswordReset(false);
      });
  };

  useEffect(() => {
    GetOrganisationByAdmin()
      .then((response) => {
        if (response?.organisation_id) {
          setOrganisationId(response.organisation_id);
          GetClassesByOrgId(response.organisation_id)
            .then((response: ClassModel[]) => {
              setClasses(response);
            })
            .catch((error) => {
              console.error('Error fetching classes:', error);
              setSnackbarMsg('Error fetching classes: ' + error.message);
              setSnackbarSeverity('error');
              setOpenSnackbar(true);
            });

          GetUsersByOrgId(response.organisation_id)
            .then(async (response) => {
              // Get current user for comparison
              let currentUser: AuthUser;
              try {
                currentUser = await getCurrentUser();
              } catch (error) {
                console.error('Error getting current user:', error);
                return;
              }

              // map response to array of StudentModel
              const users = response.map((user: any) => {
                // check if the user is the currently logged in user
                if (user.Username === currentUser.userId) {
                  return {
                    id: user.Username,
                    lastName: user.UserAttributes.find(
                      (attr: { Name: string; Value: string }) =>
                        attr.Name === 'family_name'
                    )?.Value,
                    firstName:
                      user.UserAttributes.find(
                        (attr: { Name: string; Value: string }) =>
                          attr.Name === 'given_name'
                      )?.Value + ' (You)',
                    classCount: user.classCount,
                    email: user.UserAttributes.find(
                      (attr: { Name: string; Value: string }) =>
                        attr.Name === 'email'
                    )?.Value
                  };
                }

                return {
                  id: user.Username, // cognito username which is a UUID
                  lastName: user.UserAttributes.find(
                    (attr: { Name: string; Value: string }) =>
                      attr.Name === 'family_name'
                  )?.Value,
                  firstName: user.UserAttributes.find(
                    (attr: { Name: string; Value: string }) =>
                      attr.Name === 'given_name'
                  )?.Value,
                  classCount: user.classCount,
                  email: user.UserAttributes.find(
                    (attr: { Name: string; Value: string }) =>
                      attr.Name === 'email'
                  )?.Value
                };
              });

              setRows(users);
            })
            .catch((error) => {
              console.error('Error fetching users:', error);
              setSnackbarMsg('Error fetching users: ' + error);
              setSnackbarSeverity('error');
              setOpenSnackbar(true);
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching organization:', error);
        setSnackbarMsg('Error fetching organization: ' + error);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  }, []);

  return (
    <StyledContainer>
      <Box display='flex' justifyContent='flex-end' paddingBottom={2}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setOpenNewUserView(true)}
        >
          Add New User
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnMenu // hide the kebab menu on each column
        columnVisibilityModel={{
          // Hide id column
          id: false
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 }
          },
          sorting: {
            sortModel: [{ field: 'lastName', sort: 'asc' }]
          }
        }}
        pageSizeOptions={[10, 20, 50]}
        onCellClick={(cell) => {
          if (cell.field !== 'classCount' && cell.field !== 'actions') {
            setSelectedUserId(cell.row.id);
            setOpenFeedbackSummary(true);
          }
        }}
      />
      <CustomDialog
        open={openNewUserView}
        onClose={() => {
          setOpenNewUserView(false);
          setNewUser(defaultNewUser);
        }}
        title='Create New User'
        actions={
          <>
            <Button
              onClick={() => {
                setOpenNewUserView(false);
                setNewUser(defaultNewUser);
              }}
              color='primary'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleCreateUser}
              color='primary'
              disabled={disableCreateUserButton}
            >
              Create
            </Button>
          </>
        }
      >
        <Box display='flex' flexDirection='column' gap={2}>
          <Alert severity='info'>
            This will create a new user in your organisation. The user will be
            prompted to change their password on their first login.{' '}
          </Alert>
          <TextField
            key={'123'}
            fullWidth
            type='text'
            label='First Name'
            value={newUser.firstName}
            onChange={(event) =>
              setNewUser({ ...newUser, firstName: event.target.value })
            }
          />
          <TextField
            fullWidth
            type='text'
            label='Last Name'
            value={newUser.lastName}
            onChange={(event) =>
              setNewUser({ ...newUser, lastName: event.target.value })
            }
          />
          <TextField
            fullWidth
            type='email'
            label='Email'
            value={newUser.email}
            onChange={(event) =>
              setNewUser({ ...newUser, email: event.target.value })
            }
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label='New Password'
            value={newUser.password}
            onChange={(event) =>
              setNewUser({ ...newUser, password: event.target.value })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <FormControl fullWidth>
            <InputLabel id='select-class-label'>Class</InputLabel>
            <Select
              fullWidth
              label='Class'
              value={newUser.classId}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  classId: event.target.value as string
                })
              }
            >
              {classes.map((classItem) => (
                <MenuItem key={classItem.id} value={classItem.id}>
                  {classItem.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id='select-role-label'>Role</InputLabel>
            <Select
              fullWidth
              label='Role'
              value={newUser.role}
              onChange={(event) =>
                setNewUser({ ...newUser, role: event.target.value as string })
              }
            >
              <MenuItem key={'instructor'} value={'teacher'}>
                Instructor
              </MenuItem>
              <MenuItem key={'student'} value={'student'}>
                Student
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </CustomDialog>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openFeedbackSummary}
        onClose={handleCloseFeedbackSummary}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Paper
          style={{
            width: '95%',
            height: '90%', // 90% height
            overflowY: 'auto',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '3%',
              left: '1%'
            }}
            onClick={handleCloseFeedbackSummary}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <FeedbackSummary userID={selectedUserId} />
        </Paper>
      </Modal>
      <Modal // user profile modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openProfile}
        onClose={handleClosePreview}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Paper
          style={{
            width: '95%',
            height: '90%', // 90% height
            overflowY: 'auto',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '3%',
              left: '1%'
            }}
            onClick={handleClosePreview}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <Profile
            userId={selectedUserId}
            userFirstname={selectedUserFirstname}
          />
        </Paper>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
      {/* reset password dialog */}
      <CustomDialog
        open={openPasswordReset}
        onClose={() => {
          setOpenPasswordReset(false);
          setNewPassword(''); // clear the password
        }}
        title='Reset User Password'
        actions={
          <>
            <Button
              onClick={() => {
                setOpenPasswordReset(false);
                setNewPassword(''); // clear the password}
              }}
              color='primary'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleResetPassword}
              color='primary'
            >
              OK
            </Button>
          </>
        }
      >
        <Box marginBottom={2}>
          <Alert severity='warning'>
            This will reset the user's password. The password will be copied to
            the clipboard once successfully reset. The user will be prompted to
            change their password on their first login.
          </Alert>
        </Box>
        <TextField
          key={'123'}
          fullWidth
          helperText='Please enter new password'
          id='demo-helper-text-misaligned'
          type={showPassword ? 'text' : 'password'}
          label='New Password'
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </CustomDialog>
    </StyledContainer>
  );
}
