import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import {
  GetOrganisationByAdmin,
  ListUserClasses,
  getGame
} from '../../clients/rotr-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';

function NavCompact() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [pages, setPages] = React.useState(['Home']);
  const [displayShakeDown, setDisplayShakeDown] = React.useState(false);
  const [anchorElPractice, setAnchorElPractice] =
    React.useState<null | HTMLElement>(null);
  const [anchorElPracticeMenu, setAnchorElPracticeMenu] =
    React.useState<null | HTMLElement>(null);
  const isPracticeMenuOpen = Boolean(anchorElPracticeMenu);
  const [practiceAnchorEl, setPracticeAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [practiceOpen, setPracticeOpen] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    const checkTeacher = async () => {
      try {
        const teacherClasses = await ListUserClasses('teacher');
        if (teacherClasses.length > 0) {
          setPages([
            'Home',
            'Practice',
            'Tasks',
            'Feedback',
            'Instructor',
            'Help'
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const checkAdministrator = async () => {
      try {
        const adminOrg = await GetOrganisationByAdmin();
        if (adminOrg?.organisation_id) {
          setPages([
            'Home',
            'Practice',
            'Tasks',
            'Feedback',
            'Instructor',
            'Administrator',
            'Help'
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const checkUser = async () => {
      setPages(['Home', 'Practice', 'Tasks', 'Feedback', 'Help']);
      await checkTeacher();
      await checkAdministrator();
    };

    checkUser();
    const checkGame = async () => {
      const gameResponse = await getGame();
      if (gameResponse?.id) setDisplayShakeDown(true);
    };
    checkGame();
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePracticeMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPracticeMenu(event.currentTarget);
  };

  const handlePracticeMenuClose = () => {
    setAnchorElPracticeMenu(null);
  };

  const handlePracticeMenuClick = (option: string) => {
    navigate(`/${option.toLowerCase()}`);
    handlePracticeMenuClose();
  };

  const renderPracticeMenuItems = () => {
    const items = [
      <MenuItem
        key='scenarios'
        onClick={() => handlePracticeMenuClick('practice')}
      >
        Scenarios
      </MenuItem>,
      <Tooltip
        key='recite'
        title='practice reciting the rules'
        placement='right'
      >
        <MenuItem onClick={() => handlePracticeMenuClick('practice/recite')}>
          Recite
        </MenuItem>
      </Tooltip>
    ];

    if (displayShakeDown) {
      items.push(
        <Tooltip
          key='Quizzes'
          title='Play along and learn the rules'
          placement='right'
        >
          <MenuItem onClick={() => handlePracticeMenuClick('games/shake-down')}>
            Quizzes
          </MenuItem>
        </Tooltip>
      );
    }

    return items;
  };

  const handlePracticeClick = () => {
    setPracticeOpen(!practiceOpen);
  };

  const renderPracticeItems = () => {
    const items = [
      { text: 'Scenarios', path: '/practice' },
      {
        text: 'Recite',
        path: '/practice/recite',
        tooltip: 'practice reciting the rules'
      }
    ];

    if (displayShakeDown) {
      items.push({
        text: 'Quizzes',
        path: '/games/shake-down',
        tooltip: 'Play along and learn the rules'
      });
    }

    return (
      <Collapse in={practiceOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {items.map((item) => (
            <Tooltip
              key={item.text}
              title={item.tooltip || ''}
              placement='right'
            >
              <ListItem
                button
                onClick={() => {
                  navigate(item.path);
                  handleCloseNavMenu();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Collapse>
    );
  };

  const handleMenuItemClick = (
    page: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (page === 'Practice') {
      handlePracticeClick();
    } else if (page === 'Logout') {
      // Remove direct Auth
    } else {
      navigate(`/${page.toLowerCase()}`);
      handleCloseNavMenu();
    }
  };

  const renderMenuItems = () => {
    const menuItems = pages.flatMap((page) => {
      const items = [
        <MenuItem
          key={page}
          onClick={(event) => handleMenuItemClick(page, event)}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography textAlign='center'>{page}</Typography>
          {page === 'Practice' &&
            (practiceOpen ? <ExpandLess /> : <ExpandMore />)}
        </MenuItem>
      ];

      if (page === 'Practice') {
        items.push(
          <Collapse
            key={`${page}-collapse`}
            in={practiceOpen}
            timeout='auto'
            unmountOnExit
          >
            <List component='div' disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handlePracticeMenuClick('practice')}
              >
                <ListItemText primary='Scenarios' />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handlePracticeMenuClick('practice/recite')}
              >
                <ListItemText primary='Recite' />
              </ListItemButton>
              {displayShakeDown && (
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => handlePracticeMenuClick('games/shake-down')}
                >
                  <ListItemText primary='Quizzes' />
                </ListItemButton>
              )}
            </List>
          </Collapse>
        );
      }

      return items;
    });

    return menuItems;
  };

  return (
    <>
      <IconButton
        size='large'
        aria-label='menu'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleOpenNavMenu}
        color='inherit'
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: 'block',
          '& .MuiPaper-root': {
            backgroundColor: '#1E2329'
          },
          '& .MuiMenuItem-root, & .MuiListItemButton-root': {
            color: 'whitesmoke',
            '&:hover': {
              backgroundColor: '#2C3138'
            }
          }
        }}
      >
        {renderMenuItems()}
        <MenuItem onClick={(event) => handleMenuItemClick('Logout', event)}>
          <Typography textAlign='center'>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default NavCompact;
