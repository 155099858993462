import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Paper,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getFeedbackSummaryRecite } from '../../clients/rotr-client';
import WhiteSelect from './WhiteSelect';

interface ReciteFeedbackSummaryProps {
  userID?: string;
  feedbacks?: any[]; // Add this line
}

const ReciteFeedbackSummary: React.FC<ReciteFeedbackSummaryProps> = ({
  userID,
  feedbacks: initialFeedbacks = [] // Add this line
}) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dataRows, setDataRows] = useState<any[]>(initialFeedbacks);
  const [totalRows, setTotalRows] = useState(0);
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
  const [loadingData, setLoadingData] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseError = () => setOpenError(false);

  const difficultyOptions = [
    { label: 'Easy', value: '30' },
    { label: 'Medium', value: '70' },
    { label: 'Hard', value: '100' },
    { label: 'No Prompt', value: '101' }
  ];

  const getDifficultyLevel = (percentage: number): string => {
    if (percentage === 101) return 'No Prompt';
    if (percentage <= 30) return 'Easy';
    if (percentage <= 70) return 'Medium';
    return 'Hard';
  };

  const columns: GridColDef[] = [
    { field: 'started_at', headerName: 'Date & Time', width: 200 },
    { field: 'rule_id', headerName: 'Rule', width: 150 },
    {
      field: 'accuracy',
      headerName: 'Accuracy',
      width: 100,
      valueFormatter: (params) => `${params.value.toFixed(0)}%`
    },
    {
      field: 'cloze_percentage',
      headerName: 'Difficulty Level',
      width: 150,
      valueFormatter: (params) => getDifficultyLevel(params.value)
    }
  ];

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) {
      return 'N/A'; // Return 'N/A' instead of an empty string for clarity
    }

    // First, try parsing the date directly
    let date = new Date(dateString);

    // If it's invalid, try parsing it assuming it's in ISO format
    if (isNaN(date.getTime())) {
      date = new Date(dateString.replace(' ', 'T') + 'Z');
    }

    // If it's still invalid, log and return the original string
    if (isNaN(date.getTime())) {
      return dateString;
    }

    // If valid, return the formatted date
    return date.toLocaleString();
  };

  const fetchReciteFeedbackSummary = async () => {
    if (initialFeedbacks.length > 0) {
      setDataRows(
        initialFeedbacks.map((row, index) => {
          return {
            ...row,
            id: index,
            started_at: formatDate(row.started_at)
          };
        })
      );
      setTotalRows(initialFeedbacks.length);
      return;
    }

    setLoadingData(true);
    try {
      const response = await getFeedbackSummaryRecite({
        pageSize,
        page: page + 1, // backend is 1-indexed
        started_at: startDateFilter?.toISOString(),
        ended_at: endDateFilter?.toISOString(),
        user_id: userID
      });

      const formattedRows = response.data.map((row: any, index: number) => ({
        ...row,
        id: index,
        started_at: formatDate(row.created_at)
      }));

      setDataRows(formattedRows);
      setTotalRows(response.total_hits);
    } catch (err: any) {
      setErrMsg(err.message);
      setOpenError(true);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchReciteFeedbackSummary();
  }, [pageSize, page, startDateFilter, endDateFilter, userID]);

  const datePickerStyle = {
    // ... (keep the existing datePickerStyle object)
  };

  const selectStyle = {
    // ... (keep the existing selectStyle object)
  };

  return (
    <div className={userID ? 'modal-content' : 'main-content'}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingData}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box padding={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '20px',
            flexWrap: 'wrap'
          }}
          gap={2}
        >
          Filters
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Start date'
              slotProps={{
                actionBar: {
                  actions: ['clear']
                },
                layout: {
                  sx: {
                    backgroundColor: '#333'
                  }
                }
              }}
              sx={datePickerStyle}
              value={startDateFilter}
              format='DD/MM/YYYY'
              onChange={(newValue: any) => setStartDateFilter(newValue)}
            />
            -
            <DatePicker
              label='End date'
              slotProps={{
                actionBar: {
                  actions: ['clear']
                },
                layout: {
                  sx: {
                    backgroundColor: '#333'
                  }
                }
              }}
              sx={datePickerStyle}
              format='DD/MM/YYYY'
              value={endDateFilter}
              onChange={(newValue: any) => setEndDateFilter(newValue)}
            />
          </LocalizationProvider>
        </Box>

        <Paper
          sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#333' }}
        >
          <DataGrid
            rows={dataRows}
            columns={columns}
            paginationMode='server'
            rowCount={totalRows}
            pageSizeOptions={[5, 10, 25]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={({ page, pageSize }) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            disableRowSelectionOnClick
            disableColumnMenu // Add this line to remove the column menu option
            sx={{
              color: 'white',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#5243AAff',
                color: 'white'
              },
              '& .MuiDataGrid-cell': {
                borderBottom: '1px solid #444'
              },
              '& .MuiDataGrid-menuList': {
                backgroundColor: '#333'
              },
              '& .MuiList-root': {
                backgroundColor: '#333'
              },
              '& .MuiTablePagination-root': {
                color: 'white'
              },
              '& .MuiSvgIcon-root': {
                color: 'white'
              },
              '& .MuiPickersToolbar-root': {
                backgroundColor: '#333'
              },
              '& .MuiDateCalendar-root': {
                backgroundColor: '#333'
              }
            }}
          />
        </Paper>
      </Box>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReciteFeedbackSummary;
