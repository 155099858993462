import * as React from 'react';
import {
  DataGrid,
  GridColDef,
  GridDeleteIcon,
  GridValueGetterParams
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  AddClassToOrg,
  GetClassesByOrgId,
  GetOrganisationByAdmin,
  UpdateClass
} from '../../clients/rotr-client';
import { styled } from '@mui/material/styles';
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Tooltip
} from '@mui/material';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import Instructor from '../instructor/Instructor';
import Instructors from './Instructors';
import CustomDialog from '../common/CustomDialog';
import { set } from 'lodash';

interface ClassModel {
  id: number;
  code: string;
  student_count: number;
  teacher_count: number;
}

const StyledContainer = styled(Container)({
  textAlign: 'center',
  '@media (min-width: 600px)': {
    maxWidth: '600px' // Set max width to 60px on large screens
  }
});

export default function Classes() {
  const [organisationId, setOrganisationId] = useState('');
  const [openInstructorView, setOpenInstructorView] = useState(false);
  const [openInstructorList, setOpenInstructorList] = useState(false);
  const [selectedClassCode, setSelectedClassCode] = useState('');
  const [selectedClassId, setSelectedClassId] = useState('');
  const [rows, setRows] = useState<ClassModel[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [openNewClassView, setOpenNewClassView] = useState(false);
  const [newClassCode, setNewClassCode] = useState(''); // new class code to be added
  const [openClassNameView, setOpenClassNameView] = useState(false);

  const handleCloseError = () => setOpenSnackbar(false);

  const handleAddNewClass = () => {
    AddClassToOrg(organisationId, newClassCode)
      .then((response) => {
        setSnackbarMsg(`new class ${newClassCode} added successfully`);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setOpenNewClassView(false);
        setNewClassCode('');
        // get classes by org_id
        GetClassesByOrgId(response.organisation_id)
          .then((response: ClassModel[]) => {
            setRows(response);
          })
          .catch((error) => {
            console.error('Error fetching classes:', error);
            setSnackbarMsg('Error fetching classes: ' + error);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
          });
      })
      .catch((error) => {
        console.error('Error adding new class:', error);
        setSnackbarMsg(
          'Error adding new class: ' + error.response?.data || error.message
        );
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleUpdateClass = () => {
    UpdateClass(organisationId, selectedClassId, newClassCode)
      .then((response) => {
        setSnackbarMsg(`new class code ${newClassCode} updated`);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setOpenClassNameView(false);
        setNewClassCode('');
        setSelectedClassId('');
        // get classes by org_id
        GetClassesByOrgId(response.organisation_id)
          .then((response: ClassModel[]) => {
            setRows(response);
          })
          .catch((error) => {
            console.error('Error fetching classes:', error);
            setSnackbarMsg('Error fetching classes: ' + error);
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
          });
      })
      .catch((error) => {
        console.error('Error updating class code:', error);
        setSnackbarMsg(
          'Error updating class code: ' + error.response?.data || error.message
        );
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'code', headerName: 'Class', flex: 1 },
    {
      field: 'student_count',
      headerName: 'Students',
      minWidth: 30,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            setSelectedClassCode(params.row.code);
            setOpenInstructorView(true);
          }}
        >
          {params.row.student_count}
        </Button>
      )
    },
    {
      field: 'teacher_count',
      headerName: 'Instructors',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            setSelectedClassId(params.row.id);
            setSelectedClassCode(params.row.code);
            setOpenInstructorList(true);
          }}
        >
          {params.row.teacher_count}
        </Button>
      )
    },
    {
      field: 'dummy',
      headerName: 'Actions',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell(params) {
        const buttonDisabled = !(
          Number(params.row.student_count) === 0 &&
          Number(params.row.teacher_count) === 0
        );
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title='Edit Class Name' arrow>
                <IconButton
                  size='small'
                  sx={{
                    color: 'white'
                  }}
                  onClick={() => {
                    setNewClassCode(params.row.code);
                    setSelectedClassId(params.row.id);
                    setOpenClassNameView(true);
                  }}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      }
    }
  ];

  const handleCloseInstructorView = () => setOpenInstructorView(false);
  const handleCloseInstructorList = () => setOpenInstructorList(false);

  useEffect(() => {
    // get classes for the user
    GetOrganisationByAdmin()
      .then((response) => {
        if (response?.organisation_id) {
          setOrganisationId(response.organisation_id);
          // get classes by org_id
          GetClassesByOrgId(response.organisation_id)
            .then((response: ClassModel[]) => {
              setRows(response);
            })
            .catch((error) => {
              console.error('Error fetching classes:', error);
              setSnackbarMsg('Error fetching classes: ' + error.message);
              setSnackbarSeverity('error');
              setOpenSnackbar(true);
            });
        }
      })
      .catch((error) => {
        console.error('Error fetching organisation info:', error);
        setSnackbarMsg('Error fetching organisation info: ' + error.message);
        setOpenSnackbar(true);
      });
  }, []);

  return (
    <StyledContainer>
      <Box>
        <Box display='flex' justifyContent='flex-end' paddingBottom={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpenNewClassView(true)}
          >
            Add New Class
          </Button>
        </Box>
        <DataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnMenu // hide the kebab menu on each column
          columnVisibilityModel={{
            // Hide id, scenarioAttemps and email columns
            id: false
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 }
            },
            sorting: {
              sortModel: [{ field: 'code', sort: 'desc' }]
            }
          }}
          pageSizeOptions={[10, 20, 50]}
          onCellClick={(cell) => {
            if (cell.field === 'code') {
              // other cells have their own click handlers
              setSelectedClassCode(cell.row.code);
              setOpenInstructorView(true);
            }
          }}
        />
      </Box>
      <CustomDialog
        open={openNewClassView}
        onClose={() => {
          setOpenNewClassView(false);
          setNewClassCode('');
        }}
        title='Add New Class'
        actions={
          <>
            <Button
              onClick={() => {
                setOpenNewClassView(false);
                setNewClassCode('');
              }}
              color='primary'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleAddNewClass}
              color='primary'
            >
              OK
            </Button>
          </>
        }
      >
        <Box marginBottom={2}>
          <Alert severity='info'>
            This will register a new class with the specified class code. Note
            class codes must be unique.{' '}
          </Alert>
        </Box>
        <TextField
          key={'123'}
          fullWidth
          helperText='Please enter new class code'
          id='demo-helper-text-misaligned'
          type='text'
          label='New Class Code'
          value={newClassCode}
          onChange={(event) => setNewClassCode(event.target.value)}
        />
      </CustomDialog>

      <CustomDialog
        open={openClassNameView}
        onClose={() => {
          setOpenClassNameView(false);
          setNewClassCode('');
        }}
        title='Change Class Code'
        actions={
          <>
            <Button
              onClick={() => {
                setOpenClassNameView(false);
                setNewClassCode('');
              }}
              color='primary'
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleUpdateClass}
              color='primary'
            >
              OK
            </Button>
          </>
        }
      >
        <Box marginBottom={2}>
          <Alert severity='info'>
            This will change the class code. Note class codes must be unique.{' '}
          </Alert>
        </Box>
        <TextField
          key={'123'}
          fullWidth
          helperText='Please enter new class code'
          id='demo-helper-text-misaligned'
          type='text'
          label='New Class Code'
          value={newClassCode}
          onChange={(event) => setNewClassCode(event.target.value)}
        />
      </CustomDialog>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openInstructorView}
        onClose={handleCloseInstructorView}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Paper
          style={{
            width: '95%',
            height: '90%', // 90% height
            overflowY: 'auto',
            overflowX: 'scroll',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '3%',
              left: '1%'
            }}
            onClick={handleCloseInstructorView}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <Instructor classCode={selectedClassCode} />{' '}
          {/* instructor view of students in selected class */}
        </Paper>
      </Modal>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openInstructorList}
        onClose={handleCloseInstructorList}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Paper
          style={{
            width: '95%',
            height: '90%', // 90% height
            overflowY: 'auto',
            color: 'white',
            backgroundColor: '#414141',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <IconButton
            size='large'
            sx={{
              color: 'white',
              position: 'absolute',
              top: '3%',
              left: '1%'
            }}
            onClick={handleCloseInstructorList}
            hidden={true}
          >
            <CancelTwoToneIcon fontSize='large' />
          </IconButton>
          <Instructors
            classId={selectedClassId}
            classCode={selectedClassCode}
          />{' '}
          {/* list of instructors in selected class */}
        </Paper>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}
