import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';

import Quiz from './components/practice/quizzes/quiz/Quiz';
import BeginStep from './components/practice/quizzes/quiz/BeginStep';
import GameplayFAQ from './components/practice/quizzes/FAQ';
import GameFormatPage from './components/practice/quizzes/Explanation';
import Nav from './components/header/nav';
import Home from './components/home/Home';
import { Amplify } from 'aws-amplify';
import Footer from './components/footer/footer';
import Practice from './components/practice/scenarios/practice';
import RecitePractice from './components/practice/recite/RecitePracticeV2';
import Feedback from './components/scenario feedback/Feedback';
import FeedbackSummary from './components/feedback summaries/feedbackSummary';
import Help from './components/help/Help';
import Profile from './components/user/profile';
import Instructor from './components/instructor/Instructor';
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment as AmplitudeExperiment } from '@amplitude/experiment-js-client';
import Hotjar from '@hotjar/browser';
import Tasks from './components/tasks/Tasks';
import Administrator from './components/admin/Administrator';
import ProtectedRoute from './components/common/ProtectedRoute';
import ShakeDownHome from './components/practice/quizzes/overview/QuizzesOverview';
import GameOver from './components/practice/quizzes/quiz/GameOver';
import ScoreBoardDisplay from './components/practice/quizzes/quiz/ScoreBoardDisplay';
import QuizRankings from './components/practice/quizzes/overview/QuizRankings';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { AuthUser } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { getCurrentUser } from 'aws-amplify/auth';
import NotFound from './components/common/NotFound';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID!,
      userPoolClientId: process.env.REACT_APP_CLIENT_ID!,
      loginWith: {
        email: true,
        phone: false,
        username: false,
        oauth: {
          domain: process.env.REACT_APP_OATH_DOMAIN!,
          scopes: ['email'],
          redirectSignIn: [process.env.REACT_APP_SIGNIN_CALLBACK!],
          redirectSignOut: [process.env.REACT_APP_SIGNOUT_CALLBACK!],
          responseType: 'code'
        }
      }
    }
  }
});

// Set up cookie storage
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    sameSite: 'strict',
    expires: 30
  })
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);

  const checkUser = async () => {
    try {
      const user = await getCurrentUser();
      setCurrentUser(user);
      setUserEmail(user.signInDetails?.loginId || null);
      setIsAuthenticated(true);
    } catch (error) {
      console.log('Not authenticated', error);
      setCurrentUser(null);
      setUserEmail(null);
      setIsAuthenticated(false);
    } finally {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          checkUser();
          break;
        case 'signedOut':
          setIsAuthenticated(false);
          setCurrentUser(null);
          setUserEmail(null);
          break;
        case 'tokenRefresh':
          checkUser();
          break;
        default:
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  function Navigation() {
    const location = useLocation();
    const parts = location.pathname.split('/').filter(Boolean);
    const firstPart = parts[0];
    return firstPart === 'games' ? null : (
      <Nav isAuthenticated={isAuthenticated} userEmail={userEmail} />
    );
  }
  return (
    <div className='App'>
      <BrowserRouter>
        <Navigation />
        {authLoading ? (
          <div>Loading...</div>
        ) : (
          <Routes>
            <Route
              path='/'
              element={
                <Home
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  userEmail={userEmail}
                  isLoading={authLoading}
                />
              }
            />
            <Route
              path='/home'
              element={
                <Home
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  userEmail={userEmail}
                  isLoading={authLoading}
                />
              }
            />
            <Route
              path='games/shake-down/quiz/:stepId'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Quiz />
                </ProtectedRoute>
              }
            />
            <Route
              path='/games/shake-down/steps/:stepId/intro'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <BeginStep />
                </ProtectedRoute>
              }
            />
            <Route
              path='/shake-down/faq'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <GameplayFAQ />
                </ProtectedRoute>
              }
            />
            <Route
              path='/shake-down/rankings'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <QuizRankings />
                </ProtectedRoute>
              }
            />
            <Route
              path='/shake-down/explanation'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <GameFormatPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/games/shake-down/game-over/:attemptId'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <GameOver />
                </ProtectedRoute>
              }
            />

            <Route
              path='/games/shake-down/scoreboard/:stepId'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ScoreBoardDisplay />
                </ProtectedRoute>
              }
            />

            <Route
              path='/practice'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Practice />
                </ProtectedRoute>
              }
            />
            <Route
              path='/practice/recite/:ruleId?/:subRuleId?'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <RecitePractice />
                </ProtectedRoute>
              }
            />
            <Route
              path='/games/shake-down'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <ShakeDownHome />
                </ProtectedRoute>
              }
            />
            <Route
              path='/practice/:paramID?/:trafficLvl?/:env?'
              Component={Practice}
            />
            <Route
              path='/feedback/:id'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <FeedbackSummary />
                </ProtectedRoute>
              }
            />
            <Route
              path='/feedback'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <FeedbackSummary />
                </ProtectedRoute>
              }
            />
            <Route
              path='/tasks'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Tasks />
                </ProtectedRoute>
              }
            />
            <Route
              path='/instructor'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Instructor />
                </ProtectedRoute>
              }
            />
            <Route
              path='/administrator'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Administrator />
                </ProtectedRoute>
              }
            />
            <Route path='/help' Component={Help} />
            <Route
              path='/profile'
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path='*' element={<NotFound />} />
          </Routes>
        )}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
