import React, { useEffect, useState, useRef } from 'react';
import { IconButton, Snackbar, Alert } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { connectToTranscriptionWebSocket } from '../../../clients/rotr-client';
import { normalizeText } from '../../practice/recite/stringHelper';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import CircularProgress from '@mui/material/CircularProgress';
import { getAccessToken } from '../../../clients/rotr-client';

interface MicComponentProps {
  setRuleInputString?: (ruleInput: string) => void;
  setRuleInputObject?: (ruleInput: Record<string, string>) => void;
  setCurrentTranscription: (currentTranscription: string) => void;
  currentTranscription: string;
  input?: string;
  currentRuleObject?: Record<string, string>;
  currentRuleString: string;
  ruleId: string;
  difficulty: string;
}
declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}
const MicComponent: React.FC<MicComponentProps> = ({
  setRuleInputString,
  currentRuleString,
  setRuleInputObject,
  currentRuleObject,
  ruleId,
  difficulty
}) => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [wsState, setWsState] = useState<WebSocket>();
  const recordRTC = useRef<RecordRTCPromisesHandler | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [openError, setOpenError] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<
    'error' | 'success' | 'info' | 'warning' | undefined
  >('error');
  const handleCloseError = () => setOpenError(false);
  let displayTranscription: string = '';
  let finalTranscriptions: string = '';
  const isStreamingRef = useRef(isStreaming);
  isStreamingRef.current = isStreaming;

  const reconstructTranscription = (results: Array<any>) => {
    if (
      results &&
      results.length > 0 &&
      results[0].Alternatives &&
      results[0].Alternatives.length > 0
    ) {
      const newTranscript = normalizeText(
        results[0].Alternatives[0].Transcript
      );
      const isPartial = results[0].IsPartial;
      if (!isPartial) {
        // Once a final transcription is received the rule string should be updated and the transcript saved so that it can be used to construct final and partial transcriptions together.
        finalTranscriptions +=
          (finalTranscriptions.length > 0 &&
          finalTranscriptions[finalTranscriptions.length - 1] != ' '
            ? ' '
            : '') + newTranscript;
        displayTranscription = finalTranscriptions;
        setInput(displayTranscription);
      } else {
        // This displays a live transcription so that the user can see that transcription is taking place.
        displayTranscription =
          finalTranscriptions +
          (finalTranscriptions.length > 0 &&
          finalTranscriptions[finalTranscriptions.length - 1] != ' '
            ? ' '
            : '') +
          newTranscript;
        setInput(displayTranscription);
      }
    }
  };
  const setInput = (inputString: string) => {
    if (setRuleInputString && !setRuleInputObject) {
      setRuleInputString(inputString);
    } else if (
      setRuleInputObject &&
      !setRuleInputString &&
      currentRuleObject &&
      ruleId
    ) {
      setRuleInputObject({ ...currentRuleObject, [ruleId]: inputString });
    } else {
      console.error('The required input data is not defined');
    }
  };
  const getInput = () => {
    if (currentRuleString) {
      return currentRuleString;
    } else if (currentRuleObject && ruleId) {
      return currentRuleObject[ruleId];
    } else {
      return '';
    }
  };

  const startRecording = async () => {
    if (isRecording || isStreaming || isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      finalTranscriptions = getInput(); // Persist the previous transcription
      // Connect WS
      const newWS = await connectToTranscriptionWebSocket();
      setWsState(newWS);
      const token = await getAccessToken();
      if (!isStreaming) {
        newWS.onopen = () => {
          newWS.send(
            JSON.stringify({
              token: token
            })
          );
          setIsStreaming(true);
          // Start recording audio
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream): void => {
              recordRTC.current = new RecordRTCPromisesHandler(stream, {
                type: 'audio',
                mimeType: 'audio/webm',
                sampleRate: 44100,
                desiredSampRate: 16000,
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1,
                timeSlice: 100,
                ondataavailable(blob) {
                  newWS.send(blob);
                }
              });

              recordRTC.current.startRecording();
              setIsRecording(true);
              setIsLoading(false);
            });
        };
      }
      newWS.onmessage = (message) => {
        let results;
        try {
          results = JSON.parse(message.data);
        } catch (error) {
          console.error('Error parsing message data:', error);
          return;
        }
        if (results.error) {
          stopRecording(newWS, false);
          setAlertSeverity('error');
          setErrMsg(results.error);
          setOpenError(true);
        } else {
          reconstructTranscription(results);
        }
      };
      newWS.onclose = () => {
        setIsStreaming(false);
        setIsRecording(false);
        setIsLoading(false);
      };
    } catch (error) {
      console.error('Error starting recording:', error);
      setErrMsg('Error starting transcription, contact support');
      setAlertSeverity('error');
      setIsRecording(false);
      setOpenError(true);
    }
  };

  const stopRecording = async (ws: WebSocket, delayed: boolean) => {
    // Stop recording audio
    setIsLoading(true);
    if (recordRTC.current) {
      if (delayed) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // pause for a moment to ensure the last audio data is sent
      }
      recordRTC.current.stopRecording();
      ws.send(new ArrayBuffer(0));
      setIsRecording(false);
    }
  };

  useEffect(() => {
    if (wsState && isRecording) {
      stopRecording(wsState, false);
    }
  }, [ruleId, difficulty]);
  return (
    <div>
      <IconButton
        onClick={
          isRecording
            ? () => stopRecording(wsState!, true)
            : () => startRecording()
        }
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size='1em' />
        ) : isRecording ? (
          <StopIcon color='secondary' className='pulse' />
        ) : (
          <MicIcon color='primary' />
        )}
      </IconButton>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MicComponent;
