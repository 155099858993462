import React, { useState, useEffect, useRef } from 'react';

// Own
import { processPlaceholderText, checkMatchingWords } from './stringHelper';
import '../../../App.css';
import RuleGuidanceModal from './ruleGuidanceModal';
import MicComponent from '../../common/audio/recorder';
import LinearProgress from '@mui/material/LinearProgress';
import BackspaceIcon from '@mui/icons-material/Backspace';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InsightModal from './InsightModal';
import {
  postReciteFeedback,
  updateReciteFeedback,
  getReciteFeedbackSummary
} from '../../../clients/rotr-client';
import TaskCompleteBanner from '../../tasks/TaskCompleteBanner';

// MUI
import { Snackbar, Alert } from '@mui/material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { UUID } from 'crypto';
import { Timestamp } from 'aws-sdk/clients/signer';
interface RuleInputInterface {
  ruleId: string;
  ruleString: string;
  part: string;
  section: string | undefined;
  fullTitle: string;
  ruleTitle: string;
  difficulty: string;
  difficultyLabel: string;
  showTitle: boolean;
}

const RuleInput: React.FC<RuleInputInterface> = ({
  ruleId,
  ruleString,
  part,
  section,
  fullTitle,
  ruleTitle,
  difficulty,
  difficultyLabel,
  showTitle
}) => {
  const [placeholderText, setRulePlaceholderText] =
    useState<string>('Enter rule');
  const [score, setScore] = useState<number>(0);
  const [highestSavedScore, setHighestScore] = useState<number>(0);
  const [input, setInput] = useState<string>('');
  const [currentTranscription, setCurrentTranscription] = useState<string>('');
  const inputWords = input.split(' ');
  const ruleWords = ruleString.split(' ');
  const flashThreshold: number = 100;
  const [ruleModalOpen, setRuleModalOpen] = useState<boolean>(false);
  const [InsightModalOpen, setInsightModalOpen] = useState<boolean>(false);
  const [indexRemovedWords, setIndexRemovedWords] = useState<number[]>([]);
  const [allTimeHighScore, setAllTimeHighScore] = useState<number | null>(null);
  const [ruleCoverage, setRuleCoverage] = useState<number | null>(null);
  const [latestAttempt, setLatestAttempt] = useState<Timestamp | null>(null);
  const [accuracyOverTime, setAccuracyOvertime] = useState<
    { timestamp: Timestamp; accuracy: number }[]
  >([]);
  const inputRef = useRef(input);
  const scoreRef = useRef(score);
  const inputWordsRef = useRef(inputWords);
  const highestSavedScoreRef = useRef<number>(0);
  const recordIdRef = useRef<UUID | null>(null);
  const lastSavedInputRef = useRef<string[]>();
  const difficultyRef = useRef(difficulty);
  const [recordId, setRecordId] = useState<UUID | null>(null);
  const [errMsg, setErrMsg] = useState('');
  const [openError, setOpenError] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<
    'error' | 'success' | 'info' | 'warning' | undefined
  >('error');
  const [response, setResponse] = useState<any>(null);

  useEffect(() => {
    inputRef.current = input;
    scoreRef.current = score;
    inputWordsRef.current = inputWords;
    recordIdRef.current = recordId;
    highestSavedScoreRef.current = highestSavedScore;
  }, [input, score, recordId, highestSavedScore]);

  useEffect(() => {
    const result = processPlaceholderText(ruleString, difficulty);
    setRulePlaceholderText(result.placeholderText);
    setIndexRemovedWords(result.wordIndexRemoved);
    clearInput();
    setRecordId(null); // This starts a new "session" for the user to record their progress
  }, []);

  useEffect(() => {
    // Use previous difficulty value
    clearInput();
    // Use updated difficulty value
    const result = processPlaceholderText(ruleString, difficulty);
    setRulePlaceholderText(result.placeholderText);
    setIndexRemovedWords(result.wordIndexRemoved);
    difficultyRef.current = difficulty; // update ref
    setRecordId(null); // This starts a new "session" for the user to record their progress
  }, [difficulty, ruleId]);

  useEffect(() => {
    getReciteFeedbackSummary(ruleId, Number(difficulty))
      .then((response) => {
        response.highestAccuracy
          ? setAllTimeHighScore(response.highestAccuracy)
          : setAllTimeHighScore(null);
        response.percentageOfRuleAttempted
          ? setRuleCoverage(response.percentageOfRuleAttempted)
          : setRuleCoverage(null);
        response.latestAttempt
          ? setLatestAttempt(response.latestAttempt)
          : setLatestAttempt(null);
        response.accuracyOverTime
          ? setAccuracyOvertime(response.accuracyOverTime)
          : setAccuracyOvertime([]);
      })
      .catch((error) => {
        console.error('Error fetching recite feedback summary:', error);
        setErrMsg('Error fetching recite feedback summary');
        setAlertSeverity('error');
        setOpenError(true);
      });
  }, [InsightModalOpen]);

  useEffect(() => {
    const newScore = calculateScore(ruleWords, inputWords);
    if (score !== newScore) {
      setScore(newScore);
    }
  }, [inputWords]);

  useEffect(() => {
    return () => {
      saveData();
    };
  }, []);

  const clearInput = () => {
    setInput('');
    saveData();
  };

  useEffect(() => {
    const intervalId = setInterval(saveData, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // Reduce multiple spaces to single space
    setInput(input.replace(/\s\s+/g, ' '));
  }, [input]);

  const createWordObjects = (ruleWords: string[], inputWords: string[]) => {
    return ruleWords.map((word, index) => {
      let outcome: boolean | null = null;
      if (inputWords.length > index) {
        outcome = checkMatchingWords(word, inputWords[index]);
      }

      return {
        wordIndex: index,
        outcome: outcome,
        string: word
      };
    });
  };
  const handleCloseError = () => setOpenError(false);
  const calculateScore = (
    correctWordArray: string[],
    inputWordArray: string[]
  ) => {
    if (inputWordArray.length === 0 || correctWordArray.length === 0) return 0;
    let correctWords = 0;
    for (let i = 0; i < inputWordArray.length; i++) {
      if (checkMatchingWords(correctWordArray?.[i], inputWordArray[i])) {
        correctWords++;
      }
    }
    const newScore = Math.round((correctWords / correctWordArray.length) * 100); // Calculate the score as a percentage
    return newScore;
  };

  const saveData = async () => {
    // Check that there is a string in the input and that the input has been edited since the last save
    if (
      inputRef.current.length > 0 &&
      inputWordsRef.current !== lastSavedInputRef.current
    ) {
      const data = {
        ruleID: ruleId,
        accuracy: scoreRef.current,
        diffResult: createWordObjects(ruleWords, inputWordsRef.current),
        cloze_percentage: Number(difficultyRef.current)
      };
      lastSavedInputRef.current = inputWordsRef.current;
      if (!recordIdRef.current) {
        postReciteFeedback(
          data.ruleID,
          data.diffResult,
          data.accuracy,
          data.cloze_percentage
        )
          .then((response) => {
            setResponse(response.data);

            setRecordId(response.data.id);
          })
          .catch((error) => {
            console.error('Error: ', error);
            setErrMsg('An error occurred while saving data. Contact support.');
            setAlertSeverity('error');
            setOpenError(true);
          });
        if (data.accuracy > highestSavedScore) setHighestScore(data.accuracy);
      } else {
        if (data.accuracy > highestSavedScoreRef.current) {
          try {
            await updateReciteFeedback(
              recordIdRef.current,
              data.diffResult,
              data.accuracy
            );
          } catch (error) {
            console.error('Error updating recite feedback:', error);
            setErrMsg(
              'An error occurred while updating recite feedback. Contact support.'
            );
            setAlertSeverity('error');
            setOpenError(true);
          }
          setHighestScore(data.accuracy);
        } // Update the record with the new data if a higher score has been achieved
      }
    }
  };

  return (
    <Box
      className='recite-section'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <TaskCompleteBanner payload={response} />
      <Box className='top-toolbar'>
        {showTitle && (
          <Typography
            variant='body1'
            component='div'
            color={'primary'}
            sx={{ ml: 2, mt: 1, mb: 1, flexGrow: 1, textAlign: 'left' }}
          >
            {ruleTitle}
          </Typography>
        )}

        <Tooltip placement='top' title='Click the mic for voice to text'>
          <div>
            <MicComponent
              setRuleInputString={setInput}
              setCurrentTranscription={setCurrentTranscription}
              currentTranscription={currentTranscription}
              ruleId={ruleId}
              currentRuleString={input}
              difficulty={difficulty}
            />
          </div>
        </Tooltip>
        <Tooltip placement='top' title='Show rule text'>
          <IconButton
            color='primary'
            onClick={() => setRuleModalOpen(true)}
            sx={{ ml: 1 }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip placement='top' title='Progress insights'>
          <IconButton color='primary' onClick={() => setInsightModalOpen(true)}>
            <AutoGraphIcon sx={{ ml: 1 }} />
          </IconButton>
        </Tooltip>
        <Tooltip placement='top' title='Clear all text'>
          <IconButton onClick={clearInput}>
            <BackspaceIcon sx={{ ml: 1 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <div className='input-wrapper'>
        <div className='placeholder'>
          {placeholderText.split(' ').map((word, index) => (
            <span
              key={index}
              style={{
                visibility:
                  (input.trim() === '' ? 0 : input.trim().split(' ').length) >
                  index
                    ? 'hidden'
                    : 'visible'
              }}
            >
              {word + ' '}
            </span>
          ))}
        </div>
        <div className='inputOverlay'>
          {inputWords.map((word, index) => (
            <span
              style={{
                color: checkMatchingWords(ruleWords?.[index], word)
                  ? 'green'
                  : 'red'
              }}
              className={
                checkMatchingWords(ruleWords?.[index], word) ? 'correct' : ''
              }
              key={`${word}-${index}`}
            >
              {word + ' '}
            </span>
          ))}
        </div>

        <textarea
          className='customTextField'
          value={input}
          onChange={(event) => {
            setInput(event.target.value);
          }}
        />
      </div>
      <Box className={'bottom-toolbar'}>
        <LinearProgress
          variant='determinate'
          value={score}
          color='primary'
          style={{ height: '10px' }}
          classes={{
            colorPrimary: 'colorPrimary',
            barColorPrimary:
              score === flashThreshold ? 'flashGreen' : 'barColorPrimary'
          }}
        />
      </Box>
      <RuleGuidanceModal
        ruleId={ruleId}
        ruleString={ruleString}
        part={part}
        section={section || ''}
        fullTitle={fullTitle}
        shortTitle={ruleTitle}
        input={input}
        indexRemovedWords={indexRemovedWords}
        open={ruleModalOpen}
        onClose={() => setRuleModalOpen(false)}
      />
      <InsightModal
        ruleString={ruleString}
        ruleId={ruleId}
        fullTitle={fullTitle}
        difficulty={difficulty}
        difficultyLabel={difficultyLabel}
        triggerOpen={InsightModalOpen}
        onClose={() => setInsightModalOpen(false)}
      />
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RuleInput;
